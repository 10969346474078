import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';
import { uniqueId } from 'lodash';
import Assessio from './tests/Assessio';
import Alva from './tests/Alva';
import Hubert from './tests/Hubert';
import Icon from '../@v2/Icon';
import '../../assets/sass/candidates/tests.sass';
import Assessments from '../../constants/Assessments';
import { useSelector as useSelectorToolkit } from '../../store';
import { useApiRequestContext } from '../../context/@v2/ApiRequestContext';

const TestItem = ({ component, imgSrc, type }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div
      className={classNames(
        'bg-white border border-gray-50 shadow-xl rounded-lg pt-4 px-4 mb-5 transition-all',
        !isOpen ? 'pb-0' : 'pb-4'
      )}
    >
      <div
        className={classNames(
          'flex items-center justify-between pb-4 border-b transition-all',
          isOpen ? 'border-gray-100' : 'border-white'
        )}
      >
        <div className="h-12 flex items-center">
          <img
            src={`/img/integrations/${imgSrc}.png`}
            alt=""
            className={classNames(type === 'assessio' ? 'h-8' : 'h-12')}
          />
        </div>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
        </button>
      </div>
      <Collapse isOpen={isOpen}>{component}</Collapse>
    </div>
  );
};

const Tests = () => {
  const tests = [];
  const apiRequestContext = useApiRequestContext();
  const { data } = apiRequestContext;
  const position = useSelectorToolkit(({ settings }) => settings.position);

  const positionHasAlvalabs = useMemo(() => {
    return !!(position?.pipelineSteps || []).find(
      (item) => item.assessment && item.assessment?.type === Assessments?.alvaLabs
    );
  }, [position]);

  const positionHasHubert = useMemo(() => {
    return !!(position?.pipelineSteps || []).find(
      (item) => item.assessment && item.assessment?.type === Assessments?.hubert
    );
  }, [position]);

  if (Assessments?.alvaLabs && data?.alvaLabsConfig !== null && positionHasAlvalabs) {
    tests.push({
      type: 'alva',
      imgSrc: 'alva-2',
      component: <Alva />,
    });
  }

  if (Assessments?.hubert && data?.hubertConfig && positionHasHubert) {
    tests.push({
      type: 'hubert',
      imgSrc: 'hubert',
      component: <Hubert />,
    });
  }

  if (Assessments?.assessio) {
    tests.push({
      type: 'assessio',
      imgSrc: 'assessio',
      component: <Assessio />,
    });
  }

  return (
    <div className="evaluation px-2 pb-4">
      {tests.map((item) => (
        <TestItem key={item?.type || uniqueId()} component={item?.component} imgSrc={item?.imgSrc} type={item?.type} />
      ))}
    </div>
  );
};

export default Tests;

import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import Field from '../../../../components/@v2/Form/Field';
import Button from '../../../../components/Button';
import {
  addOrUpdateIntegration,
  toggleIntegrationsActive,
} from '../../../../store/api/client-integrations.api';
import Assessments from '../../../../constants/Assessments';
import { dispatch as dispatchToolkit, useSelector } from '../../../../store';
import HubertConfig from '../../../../components/@v2/Hubert';
import { getClient } from '../../../../store/thunks/client.thunk';
import { selectClient } from '../../../../store/selectors/client.selector';
import { selectUser } from '../../../../store/selectors/user.selector';

export const HubertFormSchema = (t) => {
  return Yup.object().shape({
    apiKey: Yup.string().nullable().required(t('form-validator.required')),
  });
};

const ID = 'hubert';

const HubertForm = ({
  initialValues = {
    apiKey: '',
    assessmentConfig: { assessmentType: Assessments.hubert },
    isActive: true,
  },
}) => {
  const { id: clientId } = useSelector(selectClient);
  const { superAdmin } = useSelector(selectUser);
  const { t } = useTranslation();
  const history = useHistory();
  const isEditing = !!initialValues?.id;
  const [error, setError] = useState('');

  const onSubmit = async (values) => {
    const configModel = {
      apiKey: values?.apiKey,
      assessmentConfig: {
        ...values?.assessmentConfig,
        isActive: values?.isActive,
        reminderDelayHours: values?.assessmentConfig?.reminderDelayHours?.value,
      },
    };

    const finalData = {
      configModel,
      type: values?.type?.toLowerCase() || ID,
    };

    if (values?.id) {
      finalData.id = values?.id;
    }

    if (values?.type) {
      finalData.type = values?.type;
    }

    try {
      if (values?.id && initialValues?.isActive !== values?.isActive) {
        await toggleIntegrationsActive(
          ID,
          values?.isActive ? 'activate' : 'deactivate'
        );
      }

      await addOrUpdateIntegration(finalData);
      await getClient({
        superAdmin,
        id: clientId,
      });
      // TODO dispatchToolkit(setAlvaLabsProfiles());

      if (isEditing) {
        history.push('/settings/integrations');

        return;
      }

      history.push(`/settings/integrations/edit/${ID}`);
    } catch (error) {
      // TODO: display actual translated error message once feature from BE for setting error keys is finished
      setError(error ? t('error-message.something-went-wrong') : '');
      throw error;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={HubertFormSchema(t)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit} className="anim-table-delayed">
            {!!error && (
              <Alert color="danger" className="mb-4">
                {error}
              </Alert>
            )}
            {isEditing && (
              <>
                <div className="flex items-center justify-between mb-5">
                  <img
                    className="logo w-[150px]"
                    src={`${global?.appConfig?.public_url}/img/integrations/${ID}.png`}
                    alt="logo"
                  />
                  <Field
                    name="isActive"
                    label={t('general.active')}
                    type="switch"
                    className="!w-auto !mb-0"
                  />
                </div>
                <HubertConfig
                  form={values?.assessmentConfig}
                  setFieldValue={setFieldValue}
                />
              </>
            )}
            <Field
              name="apiKey"
              label={t('settings.integrations.api-key')}
              required
            />
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="settings-integration-submit-button"
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default HubertForm;

import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { get, sortBy, find, isArray, omit } from 'lodash';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import Layout from '../../../components/layouts/default';
import Button from '../../../components/Button';
import WithData from '../../../components/WithData';
import { TemplateInformationForm } from '../../../components/forms/position-template/TemplateInformation';
import { PipelineStepsForm } from '../../../components/forms/position-template/PipelineSteps';
import { ScreeningForm } from '../../../components/forms/position-template/Screening';
import PositionTemplateFooter from '../../../components/position-template/PositionTemplateFooter';
import { positionTemplateForm } from '../../../constants/PositionTemplate';
import {
  DefaultClientIntegration,
  RefappAutomationMailOptions,
  RefappRefereesOptions,
} from '../../../constants/ClientIntegration';
import { pipelineSteps as PipelineStepsOption } from '../../../constants/Pipeline';
import QuestionTypes from '../../../constants/ScreeningQuestionsType';
import { useAPI } from '../../../context/api';
import { addSortOrder, log } from '../../../helpers/utils';
import { getScreeningTypeById } from '../../../helpers/screening-criteria';
import { selectUser } from '../../../store/selectors/user.selector';
import {
  selectOptions,
  selectRefappQuestionaires,
  selectAlvaLabsProfiles,
  selectSriRecipients,
  selectSriOrderers,
} from '../../../store/selectors/settings.selector';
import { selectClient } from '../../../store/selectors/client.selector';
import { getAllPipelineTemplates, getAllPipelineSteps } from '../../../store/api/pipelines.api';
import { getAllEmailTemplates } from '../../../store/api/template.api';
import { getUserListSettings } from '../../../store/api/user.api';
import { createOrUpdatePositionTemplate } from '../../../store/api/position-template.api';
import { useSelector as useSelectorToolkit } from '../../../store';
import useAssessment from '../../../hooks/useAssessment';
import { getImageServiceMediaUrl } from '../../../helpers/image';

const StyledForm = styled.div`
  h3.line-behind-title + div.invalid-feedback,
  .position-form-holder + div.invalid-feedback,
  .screening-questions + div.invalid-feedback,
  .field-blanket + div.invalid-feedback,
  .screening-inputs + div.invalid-feedback,
  .screening-questions-draggable + div.invalid-feedback,
  .category-list.list-group + div.invalid-feedback {
    display: none !important;
  }
`;

const defaultPipelineAmount = 2;

const PositionTemplatesController = () => {
  const { t } = useTranslation();
  const { type, id } = useParams();
  const history = useHistory();
  const [positionTemplateAPI, screeningCriteriaAPI] = useAPI(
    'positionTemplate',
    'screeningCriteriaV2',
  );
  const currentUser = useSelectorToolkit(selectUser);
  const options = useSelectorToolkit(selectOptions);
  const templatesOptions = get(options, 'templates');
  const alvaLabsProfiles = useSelectorToolkit(selectAlvaLabsProfiles);
  const sriRecipients = useSelectorToolkit(selectSriRecipients);
  const sriOrderers = useSelectorToolkit(selectSriOrderers);
  const refappQuestionaires = useSelectorToolkit(selectRefappQuestionaires);
  const client = useSelectorToolkit(selectClient);
  const clientId = client.id;

  const [currentView, setCurrentView] = useState(1);
  const [isSending, setIsSending] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(true);
  const [isGettingPreview, setIsGettingPreview] = useState(false);
  const [pageTitle, setPageTitle] = useState('settings.position-information');
  const [formDefaults, setFormDefaults] = useState({
    ...positionTemplateForm,
    applySettings: null,
    selectedQuestion: [],
    questionsJsonString: '',
    selectedTemplate:
      templatesOptions && templatesOptions.length
        ? {
            ...templatesOptions[0],
            pipelineTemplateConnections: (templatesOptions[0].pipelineSteps || []).map((item) => ({
              ...item,
              uuid: uuidv4(),
            })),
          }
        : null,
  });

  const assessio = useAssessment();
  const { hasRefapp, hasAssessio, options: testOptions, hasAlvaLabs, hasSri, assessmentsIds } = assessio;
  const [openedItems, setOpenedItems] = useState([]);
  const [addStep, setAddStep] = useState(false);
  const [defaultSteps, setDefaultSteps] = useState([]);
  const [defaultTemplates, setDefaultTemplates] = useState([]);
  const defaultStepForm = {
    name: '',
    icon: PipelineStepsOption[0],
    testId: null,
    instructionHtml: '',
    refereeMailReferenceMethodType: RefappAutomationMailOptions(t)[0],
    referees: RefappRefereesOptions[2],
    refereeMailRequestMethodType: RefappAutomationMailOptions(t)[0],
    sriRecipientUserIds: null,
    sriOrdererUserId: null,
  };
  const [stepForm, setStepForm] = useState(defaultStepForm);
  const [questionsOptions, setQuestionsOptions] = useState([]);
  const [screeningTypes, setScreeningTypes] = useState({});
  const [previewQuestion, setPreviewQuestion] = useState([]);
  const [editorTemplates, setEditorTemplates] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    document.body.classList.add('position-page');

    return () => {
      document.body.classList.remove('position-page');
    };
  }, []);

  const clientIntegrationOptions = useMemo(() => {
    return DefaultClientIntegration.filter(
      ({ id }) =>
        (assessmentsIds.refapp === id && hasRefapp) ||
        (assessmentsIds.assessio === id && hasAssessio) ||
        (assessmentsIds.alvaLabs === id && hasAlvaLabs) ||
        (assessmentsIds.sri === id && hasSri)
    ).map((item) => ({
      ...item,
      key: item.id,
      value: item.id,
    }));
  }, [DefaultClientIntegration, hasRefapp, hasAssessio, hasAlvaLabs, hasSri]);

  // pipelines
  const getSteps = async () => {
    const response = await getAllPipelineSteps();
    if (response) {
      setDefaultSteps(
        response
          .filter((item, idx) => (response?.length >= defaultPipelineAmount ? idx < defaultPipelineAmount : idx === 0))
          .map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
            uuid: uuidv4(),
          }))
      );
    }
  };

  useEffect(() => {
    if (type === 'add') {
      setFormDefaults({ ...formDefaults, pipelineSteps: defaultSteps });
    }
  }, [type, defaultSteps]);

  const getTemplates = async () => {
    const mapItem = (templates) =>
      (templates || []).map((item) => ({
        ...item,
        value: item.id || item.templateId || item.pipelineTemplateId,
        label: item.name,
      }));
    if (!templatesOptions.length) {
      const response = await getAllPipelineTemplates();
      if (response) {
        setDefaultTemplates(mapItem(response));
      }
    } else {
      setDefaultTemplates(mapItem(templatesOptions));
    }
  };

  const resolveOrdererUser = (sriOrdererUserId) => {
    if (!sriOrdererUserId) return null;
    let ordererUser = {};

    if (typeof sriOrdererUserId === 'string') {
      ordererUser = sriOrderers?.filter(({ id }) => sriOrdererUserId.includes(id))[0];
    } else if (typeof sriOrdererUserId === 'object') {
      ordererUser = sriOrdererUserId;
    }

    // To support both api response and form data
    return {
      id: ordererUser?.id || ordererUser?.userId,
      email: ordererUser?.label || ordererUser?.email,
      label: ordererUser?.label || ordererUser?.email,
      userId: ordererUser?.id || ordererUser?.userId,
    };
  };

  const resolveRecipientUsers = (sriRecipientUserIds) => {
    if (sriRecipientUserIds && sriRecipientUserIds?.length) {
      const isArrayOfObjectUsers = !!sriRecipientUserIds[0]?.id || !!sriRecipientUserIds[0]?.userId;

      if (isArrayOfObjectUsers) {
        return sriRecipientUserIds.map(({ id, label, userId, email }) => ({
          id: userId || id,
          label: label || email,
          // this is the current value from BE
          userId: userId || id,
          email: label || email,
        }));
      }
      return sriRecipients.filter(({ id }) => sriRecipientUserIds.includes(id));
    }

    return [];
  };

  const resolveAlvaLabsTestProfileId = (alvaLabsTestProfileId) => {
    let profileId = {};

    if (typeof alvaLabsTestProfileId === 'string') {
      profileId = alvaLabsProfiles?.filter(({ id }) => alvaLabsTestProfileId.includes(id))[0];
    } else if (typeof alvaLabsTestProfileId === 'object') {
      profileId = alvaLabsTestProfileId;
    }

    // To support both api response and form data
    return profileId;
  };

  const resolveHubertInterviewTemplateId = (hubertInterviewTemplateId) => {
      let interviewId = {}; 
      if (typeof hubertInterviewTemplateId === 'string') {
        interviewId = hubertInterviewTemplates?.filter(({ id }) => hubertInterviewTemplateId.includes(id))[0];
      } else if (typeof hubertInterviewTemplateId === 'object') {
        interviewId = hubertInterviewTemplateId;
      }

      // To support both api response and form data
      return interviewId;
    };

  const resolveRefappQuestionaireId = (refappQuestionerId) => {
    if (!refappQuestionerId) return null;
    let questionaireId = {};

    if (typeof refappQuestionerId === 'string') {
      questionaireId = refappQuestionaires?.filter(({ id }) => refappQuestionerId.includes(id))[0];
    } else if (typeof refappQuestionerId === 'object') {
      questionaireId = refappQuestionerId;
    }

    // To support both api response and form data
    return questionaireId;
  };

  const adjustPipelinesteps = (existing) => {
    return (existing || []).map((item) => {
      const resolvedOrdererUser = resolveOrdererUser(
        item.assessment?.sriOrdererUserId || item.assessment?.sriOrdererUser || item.assessment?.sriOrdererUser?.userId
      );

      return {
        ...item,
        alvaLabsTestProfileId: resolveAlvaLabsTestProfileId(item.assessment?.alvaLabsTestProfileId),
        hubertInterviewTemplateId: resolveHubertInterviewTemplateId(item.assessment?.hubertInterviewTemplateId),
        refappQuestionerId: resolveRefappQuestionaireId(item.assessment?.refappQuestionerId),
        refereeMailReferenceMethodType: item.assessment?.refereeMailReferenceMethodType
          ? RefappAutomationMailOptions(t).find(
              ({ value }) => value === item.assessment?.refereeMailReferenceMethodType.toString()
            )
          : RefappAutomationMailOptions(t)[0],
        refereeMailRequestMethodType: item.assessment?.refereeMailRequestMethodType
          ? RefappAutomationMailOptions(t).find(
              ({ value }) => value === item.assessment?.refereeMailRequestMethodType.toString()
            )
          : RefappAutomationMailOptions(t)[0],
        referees:
          item.assessment?.referees && item.assessment?.referees !== 0
            ? RefappRefereesOptions.find(({ value }) => value === item.assessment?.referees.toString())
            : RefappRefereesOptions[2],
        sriRecipientUserIds: resolveRecipientUsers(
          item.assessment?.sriRecipientUserIds || item.assessment?.sriRecipientUsers
        ),
        sriOrdererUserId: resolvedOrdererUser?.id || null,
        sriOrdererUserEmail: resolvedOrdererUser?.label || resolvedOrdererUser?.email || null,
        assessmentType: item.assessment?.type,
      };
    });
  };

  const getMappedPipelines = (value) => {
    let steps = [];
    if (defaultSteps) {
      const pipelinesForMapping = isArray(value) ? value : value?.pipelineSteps;
      (pipelinesForMapping || []).forEach((item) => {
        const foundPipelineStep = defaultSteps.find((step) => step.id === item.pipelineStepId);
        steps = [
          ...steps,
          {
            ...foundPipelineStep,
            ...item,
            id: item.id || item.pipelineStepId || 0,
            pipelineStepId: item.pipelineStepId || 0,
            sortOrder: item.sortOrder,
            uuid: item.uuid || uuidv4(),
          },
        ];
      });
    }

    return steps;
  };

  const getTemplateOptions = (selected) => {
    if (selected) {
      const { pipelineTemplateConnections, pipelineSteps } = selected;
      if (pipelineTemplateConnections || pipelineSteps) {
        return getMappedPipelines(pipelineTemplateConnections || pipelineSteps);
      }
      return getMappedPipelines(selected);
    }
    return null;
  };

  const getTests = async () => {
    try {
      if (defaultSteps && defaultTemplates) {
        let steps = defaultSteps.map((item) => {
          if (!assessio.testIds.includes(item.assessmentType)) {
            item.testId = null;
          }
          return item;
        });

        if (steps.length === 0 && defaultTemplates.length) {
          const { pipelineTemplateConnections, pipelineSteps } = defaultTemplates[0];

          if (pipelineTemplateConnections || pipelineSteps) {
            (pipelineTemplateConnections || pipelineSteps).forEach((item) => {
              const foundPipelineStep = defaultSteps.find((step) => step.id === item.pipelineStepId);

              if (foundPipelineStep) {
                steps = [
                  ...steps,
                  {
                    ...foundPipelineStep,
                    ...item,
                    id: 0,
                    sortOrder: item.sortOrder,
                  },
                ];
              }
            });
          }
        }

        setFormDefaults({
          ...formDefaults,
          pipelineSteps: steps,
        });
      }
    } catch (error) {
      log(error);
    }
  };

  const getPipelineSteps = (formData) => {
    // if nothing selected, set default
    const steps = get(formData, 'selectedTemplate')
      ? sortBy(getTemplateOptions(get(formData, 'selectedTemplate')), 'sortOrder')
          .filter((item) => get(item, 'test.active') !== false)
          .map((item) => {
            const assessmentTypeValue =
              item.assessmentType?.value || item.assessmentType || item.assessment?.type || null;
            return {
              ...item,
              icon: PipelineStepsOption.find((pipeline) => pipeline.value === item.icon) || PipelineStepsOption[0],
              test: assessio.findTest(item.assessmentType),
              integrationType:
                clientIntegrationOptions.find(({ id }) => {
                  if (item?.refappQuestionerId?.id) {
                    return id === assessmentsIds.refapp;
                  }

                  if (item?.alvaLabsTestProfileId?.id) {
                    return id === assessmentsIds.alvaLabs;
                  }

                  if (item?.hubertInterviewTemplateId?.id) {
                    return id === assessmentsIds.hubert;
                  }

                  if (assessmentTypeValue === 300 || item?.sriOrdererUserId || item?.sriOrdererUserId?.id) {
                    return id === assessmentsIds.sri;
                  }

                  if (item?.assessmentType) {
                    return id === assessmentsIds.assessio;
                  }

                  return false;
                }) || null,
              assessmentType:
                assessmentTypeValue && assessmentTypeValue !== 200
                  ? testOptions.find(({ value }) => value === assessmentTypeValue)
                  : assessmentTypeValue,
              refappQuestionerId: resolveRefappQuestionaireId(get(item, 'refappQuestionerId')),
              alvaLabsTestProfileId: resolveAlvaLabsTestProfileId(get(item, 'alvaLabsTestProfileId')),
              hubertInterviewTemplateId: resolveHubertInterviewTemplateId(get(item, 'hubertInterviewTemplateId')),
              resolveHubertInterviewTemplateId: resolveHubertInterviewTemplateId(item?.hubertInterviewTemplateId),
              refereeMailReferenceMethodType: get(item, 'refereeMailReferenceMethodType')
                ? RefappAutomationMailOptions(t).find(
                    ({ value }) =>
                      value === (item.refereeMailReferenceMethodType?.value || item.refereeMailReferenceMethodType)
                  )
                : null,
              referees: get(item, 'referees')
                ? RefappRefereesOptions.find(({ value }) => value === (item.referees?.value || item.referees))
                : null,
              refereeMailRequestMethodType: get(item, 'refereeMailRequestMethodType')
                ? RefappAutomationMailOptions(t).find(
                    ({ value }) =>
                      value === (item.refereeMailRequestMethodType?.value || item.refereeMailRequestMethodType)
                  )
                : null,
              sriRecipientUserIds: resolveRecipientUsers(item?.sriRecipientUserIds),
              sriOrdererUserId: resolveOrdererUser(item?.sriOrdererUserId),
            };
          })
      : defaultSteps;
    return steps;
  };

  const onDragStepEnd = (val, form, setForm) => {
    const { destination, draggableId } = val;
    if (!destination) return;
    const step = getPipelineSteps(form).find((item) => item.uuid === draggableId);
    const steps = getPipelineSteps(form).filter((item) => item.uuid !== draggableId);

    steps.splice(destination.index, 0, step);
    const mappedSteps = steps.map((item) => ({
      ...item,
      icon: get(item, 'icon.value'),
    }));
    setForm({
      ...form,
      pipelineSteps: addSortOrder(mappedSteps),
      selectedTemplate: {
        ...form.selectedTemplate,
        pipelineTemplateConnections: addSortOrder(mappedSteps),
      },
    });
  };

  const onSaveSteps = ({ values, clientId, setAddStep, form, setForm, setFormDefaults }) => {
    const icon = get(values, 'icon.value') || values.icon;
    const currentSteps = getPipelineSteps(form);
    let updatedSteps = [];

    if (find(currentSteps, { uuid: values.uuid })) {
      updatedSteps = currentSteps.map((item) => {
        if (item.uuid === values.uuid) {
          if (values?.integrationType?.id) {
            return {
              ...values,
              icon,
            };
          }

          return {
            ...item,
            ...values,
            icon,
            alvaLabsTestProfileId: null,
            hubertInterviewTemplateId: null,
            assessmentType: null,
            refappQuestionerId: null,
          };
        }

        return {
          ...item,
          icon: item?.icon?.value || item?.icon,
        };
      });
    } else {
      updatedSteps = [
        ...currentSteps,
        {
          ...values,
          icon,
          clientId,
          positionId: values.postitionId || 0,
          id: values.id || 0,
          deleted: false,
          uuid: uuidv4(),
          added: true,
        },
      ];
    }
    const updatedForm = {
      ...form,
      pipelineSteps: addSortOrder(updatedSteps),
      selectedTemplate: {
        ...form.selectedTemplate,
        pipelineTemplateConnections: addSortOrder(updatedSteps),
      },
    };

    setForm(updatedForm);
    setFormDefaults(updatedForm);
    setStepForm(defaultStepForm);
    setOpenedItems((currentState) => currentState.filter((item) => item !== values.uuid));
    setAddStep(false);
  };

  // screening
  const getQuestions = async () => {
    try {
      const items = await screeningCriteriaAPI.lookup({
        filters: 'deleted==false',
      });

      setQuestionsOptions(
        items.map((item) => ({
          ...item,
          id: item.screeningCriteriaId,
          value: item.screeningCriteriaId,
          label: item.name,
          selections: item.selections.map((selection, index) => ({ ...selection, value: index + 1 })),
        }))
      );
    } catch (error) {
      // log(error)
    }
  };

  const loadScreeningTypes = async () => {
    const types = await screeningCriteriaAPI.getTypes();
    setScreeningTypes(types);
  };

  const getAllTemplates = async () => {
    try {
      const { items } = await getAllEmailTemplates();
      if (Array.isArray(items)) {
        setEditorTemplates(
          items
            .map((item) => ({
              value: item.text,
              label: item.name,
              id: item.id || item.templateId,
              item,
            }))
        );
      }
    } catch (e) {
      // console.error(e);
    }
  };

  const getUsers = async () => {
    try {
      const { items } = await getUserListSettings;
      setUsers(
        items.map((item) => {
          item.value = item.userId;
          item.label = item.fullName;

          return item;
        })
      );
    } catch (error) {
      // log(error)
    }
  };

  useEffect(() => {
    getQuestions();
    loadScreeningTypes();
    const permissionLevel = get(currentUser, 'permissionLevel');
    if (permissionLevel > 1) {
      getUsers();
    }
    getAllTemplates();
    getSteps();
  }, []);

  const parsedQuestions = useMemo(() => {
    return formDefaults.criterias ? sortBy(formDefaults.criterias, 'sortOrder') : [];
  }, [formDefaults.criterias]);

  const addedQuestions = useMemo(() => {
    let items = [];

    parsedQuestions.forEach((parentItem) => {
      const foundQuestion = questionsOptions.find(
        (question) => question.screeningCriteriaId === parentItem.screeningCriteriaId
      );

      if (foundQuestion) {
        const screeningType = getScreeningTypeById(foundQuestion.screeningCriteriaTypeId, screeningTypes);
        items = [
          ...items,
          {
            ...foundQuestion,
            ...parentItem,
            type: screeningType,
            selections: foundQuestion.selections.map((item) => ({
              ...item,
              value: item.screeningCriteriaId,
              label: item.name,
            })),
          },
        ];
      }
    });
    return items;
  }, [parsedQuestions, questionsOptions, QuestionTypes, screeningTypes]);

  const parsedQuestionsOptions = useMemo(() => {
    let questions = [];

    questionsOptions.forEach((option) => {
      const foundQuestion = addedQuestions.find(
        (question) => question.screeningCriteriaId === option.screeningCriteriaId
      );

      if (!foundQuestion) questions = [...questions, option];
    });

    return questions;
  }, [questionsOptions, addedQuestions]);

  useEffect(() => {
    if (type === 'add') {
      setIsLoadingForm(false);
      getTemplates();
    }
  }, [type]);

  useEffect(() => {
    if (type === 'add' && defaultTemplates.length) {
      getTests();
    }
  }, [type, defaultTemplates]);

  const inputFields = useMemo(() => {
    return [
      {
        type: 'col',
        hide: currentView !== 1,
        inputs: (
          <TemplateInformationForm
            initialValues={formDefaults}
            editorTemplates={editorTemplates}
            setFormDefaults={setFormDefaults}
          />
        ),
      },
      {
        type: 'col',
        hide: currentView !== 2,
        inputs: (
          <PipelineStepsForm
            initialValues={formDefaults}
            stepForm={stepForm}
            addStep={addStep}
            setAddStep={setAddStep}
            clientId={clientId}
            defaultTemplates={defaultTemplates}
            defaultSteps={defaultSteps}
            getTemplateOptions={getTemplateOptions}
            testOptions={assessio.options}
            onDragStepEnd={onDragStepEnd}
            onSaveSteps={onSaveSteps}
            getPipelineSteps={getPipelineSteps}
            openedItems={openedItems}
            setOpenedItems={setOpenedItems}
            questioneerOptions={refappQuestionaires}
            setFormDefaults={setFormDefaults}
            adjustPipelinesteps={adjustPipelinesteps}
            addSortOrder={addSortOrder}
          />
        ),
      },
      {
        type: 'col',
        hide: currentView !== 3,
        inputs: (
          <ScreeningForm
            initialValues={formDefaults}
            addedQuestions={addedQuestions}
            parsedQuestions={parsedQuestions}
            parsedQuestionsOptions={parsedQuestionsOptions}
            getQuestions={getQuestions}
            previewQuestion={previewQuestion}
            setPreviewQuestion={setPreviewQuestion}
            editorTemplates={editorTemplates}
            users={users}
            setFormDefaults={setFormDefaults}
            getTemplateOptions={getTemplateOptions}
            defaultTemplates={defaultTemplates}
          />
        ),
      },
    ];
  }, [
    currentView,
    stepForm,
    addStep,
    clientId,
    defaultTemplates,
    assessio,
    openedItems,
    setOpenedItems,
    addedQuestions,
    parsedQuestions,
    parsedQuestionsOptions,
    previewQuestion,
    editorTemplates,
    users,
    defaultSteps,
  ]);

  const goToView = (view) => {
    setCurrentView(view);
    const pageTitles = ['settings.position-information', 'general.pipeline-steps', 'general.screening'];
    setPageTitle(pageTitles[view - 1]);
  };

  const canGoNext = (data) => {
    const item = { ...formDefaults, ...data };
    if (currentView === 1) {
      const strippedText = (text) => text.replace(/<[^>]+>/g, '');
      if (
        item.name === '' ||
        item.positionName === '' ||
        !item.positionName ||
        item.description === '' ||
        (item.pitch && strippedText(item.pitch).length > 200)
      ) {
        return false;
      }
    } else if (currentView === 2) {
      if (item.pipelineSteps && !item.pipelineSteps.length) return false;
    }
    return true;
  };

  const onPreview = async (data) => {
    setIsGettingPreview(true);
    const formData = {
      ...formDefaults,
      ...data,
    };

    const checkIfEmpty = (text) => {
      if (!text || (text && text === '')) return t('general.not-yet-entered');
      return text;
    };

    const payload = {
      ...formData,
      name: checkIfEmpty(formData.name),
      positionName: checkIfEmpty(formData.positionName),
      description: checkIfEmpty(formData.description),
      pipelineSteps: formData?.pipelineSteps?.map((item) => ({
        ...item,
        icon: get(item, 'icon.value') ? item.icon.value : item.icon,
      })),
      clientId: currentUser.clientId?.clientId || currentUser.clientId,
    };

    try {
      const response = await positionTemplateAPI.setPreviewData(payload);
      if (response) {
        setIsGettingPreview(false);
        const win = window.open(
          `${global?.appConfig?.api_url || ''}/position-templates/preview/${response.guid}`,
          '_blank'
        );
        if (win != null) win.focus();
      }
    } catch (error) {
      // log('error', error);
    }
  };

  const getNotifiedUserIdString = (str, candidateCategoryId) => {
    const parsedJson = typeof str === 'string' ? JSON.parse(str) : str;

    if (parsedJson.notifiedUserIdJsonString) {
      const parsedNotifiedUserId = JSON.parse(parsedJson.notifiedUserIdJsonString);
      return {
        ...parsedJson,
        candidateCategoryId,
        notifiedUserIdJsonString: JSON.stringify(parsedNotifiedUserId.map((item) => item.userId || item)),
      };
    }

    return str;
  };

  const onSubmit = async (values) => {
    setIsSending(true);
    const formData = {
      ...formDefaults,
      ...values,
    };

    const existingPipelineSteps = type === 'add' ? getPipelineSteps(formData) : formData.pipelineSteps;

    const formattedData = {
      ...formData,
      pipelineSteps: existingPipelineSteps?.map((item) => {
        let assessment = null;
        let assessmentType = item?.assessmentType?.value || item?.assessmentType || null;

        if (item.refappQuestionerId?.id) {
          assessmentType = assessmentsIds.refapp;
        } else if (item.alvaLabsTestProfileId?.id) {
          assessmentType = assessmentsIds.alvaLabs;
        } else if (item.hubertInterviewTemplateId?.id) {
          assessmentType = assessmentsIds.hubert;
        } else if (item.sriOrdererUserId?.id) {
          assessmentType = assessmentsIds.sri;
        }

        if (assessmentType) {
          assessment = {
            type: assessmentType,
            alvaLabsTestProfileId: resolveAlvaLabsTestProfileId(item?.alvaLabsTestProfileId)?.id,
            hubertInterviewTemplateId: resolveHubertInterviewTemplateId(item?.hubertInterviewTemplateId)?.id,
            refappQuestionerId: resolveRefappQuestionaireId(item.refappQuestionerId)?.id,
            refereeMailReferenceMethodType: parseInt(
              get(item?.refereeMailReferenceMethodType, 'value') || item?.refereeMailReferenceMethodType,
              10
            ),
            referees: parseInt(get(item?.referees, 'value') || item?.referees, 10),
            refereeMailRequestMethodType: parseInt(
              get(item?.refereeMailRequestMethodType, 'value') || item?.refereeMailRequestMethodType,
              10
            ),
            sriRecipientUsers: resolveRecipientUsers(item?.sriRecipientUserIds),
            sriOrdererUser: resolveOrdererUser(item?.sriOrdererUserId),
          };
        }
        return omit(
          {
            ...item,
            id: item.id || 0,
            deleted: item.deleted || false,
            positionId: null,
            clientId,
            icon: item?.icon?.value || item?.icon,
            assessment,
          },
          [
            'integrationType',
            'assessmentType',
            'alvaLabsTestProfileId',
            'hubertInterviewTemplateId',
            'refappQuestionerId',
            'refereeMailReferenceMethodType',
            'referees',
            'refereeMailRequestMethodType',
            'sriOrdererUserId',
            'sriRecipientUserIds',
          ]
        );
      }),
      criterias: formData.criterias.filter((item) => item.screeningCriteriaId !== 0),
    };

    try {
      await createOrUpdatePositionTemplate(omit(formattedData, ['selectedTemplate']));
      setIsSending(false);
      history.push('/settings/templates');
    } catch (error) {
      log('error', error);
    }
  };

  return (
    <WithData
      error={type === 'edit' && !id}
      api={type === 'edit' && id ? () => positionTemplateAPI.find(id) : null}
      setItems={async (response) => {
        if (!response) return;
        setIsLoadingForm(true);
        const positionTemplate = response;
        if (!positionTemplate) return;
        const templates = await getAllPipelineTemplates();
        const steps = await getAllPipelineSteps();
        if (templates && steps) {
          setDefaultSteps(
            steps?.map((item) => ({
              ...item,
              value: item.id,
              label: item.name,
              uuid: uuidv4(),
              test: assessio.findTest(item.assessmentType),
            }))
          );
          setDefaultTemplates(
            templates?.map((item) => ({
              ...item,
              value: item.id || item.templateId || item.pipelineTemplateId,
              label: item.name,
            }))
          );
          const defaultPipelineStepsIDs = ((templates || [])[0]?.pipelineSteps || []).map(({ id }) => id);
          const defaultPipelineData = steps?.filter((item) => defaultPipelineStepsIDs.includes(item.id));

          const defaultPipelines = (
            get(positionTemplate, 'pipelineSteps').length >= 2 ? positionTemplate.pipelineSteps : defaultPipelineData
          ).map((item) => ({
            ...item,
            id: item.id || item.pipelineStepId || 0,
            pipelineStepId: item.pipelineStepId || 0,
            uuid: uuidv4(),
            fromEdit: true,
          }));

          const updatedData = {
            ...formDefaults,
            ...positionTemplate,
            pipelineSteps: defaultSteps.length
              ? getMappedPipelines(adjustPipelinesteps(defaultPipelines))
              : adjustPipelinesteps(defaultPipelines),
            selectedTemplate: {
              pipelineTemplateConnections: defaultSteps.length
                ? getMappedPipelines(adjustPipelinesteps(defaultPipelines))
                : adjustPipelinesteps(defaultPipelines),
            },
            categorySettingsA: getNotifiedUserIdString(
              positionTemplate.categorySettingsA || formDefaults.categorySettingsA,
              1
            ),
            categorySettingsB: getNotifiedUserIdString(
              positionTemplate.categorySettingsB || formDefaults.categorySettingsB,
              2
            ),
            categorySettingsC: getNotifiedUserIdString(
              positionTemplate.categorySettingsC || formDefaults.categorySettingsC,
              3
            ),
            categorySettingsOthers: getNotifiedUserIdString(
              positionTemplate.categorySettingsOthers || formDefaults.categorySettingsOthers,
              0
            ),
            criterias: positionTemplate.criterias || formDefaults.criterias,
          };

          updatedData.imageUrl = getImageServiceMediaUrl(updatedData.image);
          updatedData.pictureUrl = getImageServiceMediaUrl(updatedData.picture);

          setFormDefaults(updatedData);
        }
        setIsLoadingForm(false);
      }}
    >
      {() => (
        <>
          <Layout
            pretitle={
              type === 'edit' && id ? t('settings.edit-position-template') : t('settings.new-position-template')
            }
            title={t(pageTitle)}
            data-testid="settings-position-template-page-layout"
            headerTextStyle={{
              marginBottom: 10,
              paddingBottom: 10,
            }}
            rightContent={
              <div className="d-flex">
                <Button
                  color="primary"
                  className="d-flex align-items-center"
                  data-testid="settings-position-template-page-preview-button"
                  onClick={() => {
                    onPreview(formDefaults);
                  }}
                  loading={isGettingPreview}
                  disabled={isLoadingForm}
                >
                  {t('general.preview')}
                </Button>
              </div>
            }
          >
            {!isLoadingForm && <StyledForm>{inputFields[currentView - 1].inputs}</StyledForm>}
            <PositionTemplateFooter
              currentView={currentView}
              goToView={goToView}
              onSubmit={onSubmit}
              canGoNext={canGoNext}
              data={formDefaults}
              isSending={isSending}
            />
          </Layout>
        </>
      )}
    </WithData>
  );
};

export default PositionTemplatesController;

import React, { useState, useEffect, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, FormGroup, Label, CardHeader, CardBody } from 'reactstrap';
import { sortBy, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import Select from '../../@v2/Select/Select';
import Icon from '../../@v2/Icon';
import { addSortOrder } from '../../../helpers/utils';
import PipelineStepForm from '../PipelineStep';
import { pipelineSteps as PipelineStepsOption } from '../../../constants/Pipeline';
import {
  DefaultClientIntegration,
  RefappAutomationMailOptions,
  RefappRefereesOptions,
} from '../../../constants/ClientIntegration';
import {
  selectAlvaLabsProfiles,
  selectHubertInterviewTemplates,
  selectOptions,
  selectRefappQuestionaires,
  selectSriRecipients,
  selectSriOrderers,
} from '../../../store/selectors/settings.selector';
import { selectClient } from '../../../store/selectors/client.selector';
import { useSelector as useSelectorToolkit } from '../../../store';
import useAssessment from '../../../hooks/useAssessment';
import { getAllPipelineTemplates, getPipelineTemplate } from '../../../store/api/pipelines.api';

const PipelineSteps = ({ state, setState, isAdding, isPreselected, adjustPipelinesteps }) => {
  const { t } = useTranslation();
  const { type } = useParams();
  const [addStep, setAddStep] = useState(false);
  const [openedItems, setOpenedItems] = useState([]);
  const options = useSelectorToolkit(selectOptions);
  const [selectedTemplate, setSelectedTemplate] = useState(isAdding && !isPreselected ? [options.templates[0]] : null);

  const alvaLabsProfiles = useSelectorToolkit(selectAlvaLabsProfiles);
  const hubertInterviewTemplates = useSelectorToolkit(selectHubertInterviewTemplates);
  const refappQuestioners = useSelectorToolkit(selectRefappQuestionaires);
  const sriRecipients = useSelectorToolkit(selectSriRecipients);
  const sriOrderers = useSelectorToolkit(selectSriOrderers);

  const {
    hasRefapp,
    hasAssessio,
    hasAlvaLabs,
    hasHubert,
    hasSri,
    findTest,
    options: testOptions,
    assessmentsIds,
  } = useAssessment();
  const client = useSelectorToolkit(selectClient);

  const clientIntegrationOptions = useMemo(() => {
    return DefaultClientIntegration.filter(
      ({ id }) =>
        (assessmentsIds.refapp === id && hasRefapp) ||
        (assessmentsIds.assessio === id && hasAssessio) ||
        (assessmentsIds.alvaLabs === id && hasAlvaLabs) ||
        (assessmentsIds.hubert === id && hasHubert) ||
        (assessmentsIds.sri === id && hasSri)
    ).map((item) => ({
      ...item,
      key: item.id,
      value: item.id,
    }));
  }, [DefaultClientIntegration, hasRefapp, hasAssessio, hasAlvaLabs, hasHubert, hasSri]);

  const syncedRecipientList = useMemo(() => {
    if (!options?.syncedRecipientList) return null;
    return options.syncedRecipientList;
  }, [options.syncedRecipientList]);

  const defaultFormValues = {
    name: '',
    icon: PipelineStepsOption[0],
    testId: null,
    instructionHtml: '',
    refappQuestionerId: null,
    integrationType: null,
    refereeMailReferenceMethodType: RefappAutomationMailOptions(t)[0],
    referees: RefappRefereesOptions[2],
    refereeMailRequestMethodType: RefappAutomationMailOptions(t)[0],
    sriRecipientUserIds: syncedRecipientList,
    sriOrdererUserId: null,
  };
  const [form, setForm] = useState(defaultFormValues);

  const pipelineSteps = useMemo(() => {
    return sortBy(state?.pipelineSteps || [], 'sortOrder').map((item) => {
      let assessmentType = null;

      if (item?.refappQuestionerId?.id) {
        assessmentType = assessmentsIds.refapp;
      } else if (item?.alvaLabsTestProfileId?.id) {
        assessmentType = assessmentsIds.alvaLabs;
      } else if (item?.hubertInterviewTemplateId?.id) {
        assessmentType = assessmentsIds.hubert;
      } else {
        assessmentType = item.assessmentType || testOptions.find(({ value }) => value === item.assessmentType);
      }

      const existingRecipients = (item?.sriRecipientUserIds || []).map(({ id }) => id);

      const existingRecipientsList = sriRecipients.filter((item) => existingRecipients.includes(item.id));
      const defaultRecipients = existingRecipientsList.length ? existingRecipients : [];

      if (item.assessmentType === null && !item.integrationType) {
        assessmentType = item.assessmentType;
      }

      return {
        ...item,
        icon: PipelineStepsOption.find((pipeline) => pipeline.value === item.icon) || PipelineStepsOption[0],
        test: findTest(item.assessmentType),
        integrationType:
          clientIntegrationOptions.find(({ id }) => {
            if (item?.refappQuestionerId?.id) {
              return id === assessmentsIds.refapp;
            }

            if (item?.alvaLabsTestProfileId?.id) {
              return id === assessmentsIds.alvaLabs;
            }

            if (item?.hubertInterviewTemplateId?.id) {
              return id === assessmentsIds.hubert;
            }

            if (item.assessmentType === assessmentsIds.sri || item?.sriOrdererUserId?.id) {
              return id === assessmentsIds.sri;
            }

            if (item?.assessmentType) {
              return id === assessmentsIds.assessio;
            }

            return false;
          }) || null,
        assessmentType,
        refappQuestionerId: item.refappQuestionerId || null,
        alvaLabsTestProfileId: item.alvaLabsTestProfileId || null,
        hubertInterviewTemplateId: item.hubertInterviewTemplateId || null,
        refereeMailReferenceMethodType: get(item, 'refereeMailReferenceMethodType.value')
          ? item.refereeMailReferenceMethodType
          : RefappAutomationMailOptions(t).find(
              ({ value }) => value === item.refereeMailReferenceMethodType?.toString()
            ),
        referees: get(item, 'referees.value')
          ? item.referees
          : RefappRefereesOptions.find((item) => item.value === item.referees?.toString()),
        refereeMailRequestMethodType: get(item, 'refereeMailRequestMethodType.value')
          ? item.refereeMailRequestMethodType
          : RefappAutomationMailOptions(t).find(({ value }) => value === item.refereeMailRequestMethodType?.toString()),
        sriRecipientUserIds:
          item?.sriRecipientUserIds?.length && item?.sriRecipientUserIds?.[0]?.id
            ? item?.sriRecipientUserIds?.map(({ id }) => id)
            : sriRecipients.filter((recipient) => (item?.sriRecipientUserIds || []).includes(recipient.id)),
        sriOrdererUserId: get(item, 'sriOrdererUserId.id')
          ? item.sriOrdererUserId
          : (sriOrderers || []).find(({ id }) => id === item.sriOrdererUserId),
        sriOrdererUserEmail: get(item, 'sriOrdererUserEmail.id') ? item.sriOrdererUserEmail : defaultRecipients,
        uuid: String(item.id),
      };
    });
  }, [state.pipelineSteps, state.positionId, testOptions, refappQuestioners, alvaLabsProfiles, hubertInterviewTemplates]);

  const onDragEnd = ({ destination, draggableId }) => {
    if (!destination) return;
    const step = pipelineSteps.find((item) => item.uuid === draggableId);
    const steps = [...pipelineSteps].filter((item) => item.uuid !== draggableId);
    steps.splice(destination.index, 0, step);
    setState(
      'pipelineSteps',
      addSortOrder(
        steps.map((item) => ({
          ...item,
          icon: get(item, 'icon.value'),
        }))
      )
    );
  };

  const onSave = (values) => {
    const icon = values?.icon?.value;
    if (values.uuid) {
      const updatedSteps = [...pipelineSteps].map((item) => {
        if (item.uuid === values.uuid) {
          if (values?.integrationType?.id) {
            return {
              ...values,
              icon,
            };
          }

          return {
            ...item,
            ...values,
            icon,
            alvaLabsTestProfileId: null,
            hubertInterviewTemplateId: null,
            assessmentType: null,
            refappQuestionerId: null,
            sriOrdererUserId: null,
          };
        }

        return {
          ...item,
          icon: item?.icon?.value || item?.icon,
        };
      });

      setState('pipelineSteps', updatedSteps);
      setOpenedItems((currentState) => currentState.filter((item) => item !== String(values.id)));
      return;
    }

    const newValues = {
      ...values,
      icon,
      clientId: client.id,
      positionId: state.positionId || 0,
      deleted: false,
      added: true,
      id: values.id || uuidv4(),
    };

    delete newValues.test;
    const updatedSteps = [...pipelineSteps, newValues];
    setState('pipelineSteps', addSortOrder(updatedSteps));
    setAddStep(false);
    setForm(defaultFormValues);
  };

  useEffect(() => {
    if (!state?.pipelineSteps.length && isPreselected) {
      setSelectedTemplate(options.templates && options.templates.length ? options.templates[0] : []);
    }
  }, [state?.pipelineSteps, isPreselected]);

  const isTemplateDisabled = useMemo(() => {
    return type === 'edit' && !state.draft;
  }, [type, state]);

  const onTemplateChange = async (selected) => {
    if (isTemplateDisabled || !selected) return;
    const response = await getPipelineTemplate(selected.id);
    const pipelineSteps = addSortOrder(adjustPipelinesteps(response?.pipelineSteps) || []);
    setSelectedTemplate(selected);
    setState('pipelineSteps', pipelineSteps);
    setState('pipelineStepsTemplate', selected);
  };

  useEffect(() => {
    if (state?.pipelineStepsTemplate) {
      setSelectedTemplate([state?.pipelineStepsTemplate]);
    }
  }, [state?.pipelineStepsTemplate]);

  return (
    <div className="position-form">
      <h3 className="line-behind-title" style={{ marginTop: '10px' }}>
        {t('edit-position.recruitment-process-steps')}
      </h3>
      {state.useAlternativeApplyForm && <p>{t('edit-position.not-available-when-external-apply-form')}</p>}
      {!state.useAlternativeApplyForm && (
        <Row className="position-form-holder">
          <Col md="12">
            {((type === 'edit' && state.draft) || type === 'add') && (
              <FormGroup
                style={{
                  position: 'relative',
                  zIndex: 3,
                }}
              >
                <Label for="process-steps">{t('edit-position.choose-template-process-steps')}</Label>
                <Select
                  searchable
                  searchKey="name"
                  resource="position-pipeline-template"
                  api={getAllPipelineTemplates}
                  value={selectedTemplate}
                  keys={['id', 'name']}
                  objectFilters={{
                    page: {
                      size: 10,
                      number: 1,
                    },
                  }}
                  onSelect={(selected) => {
                    if (selected[0]) {
                      onTemplateChange(selected[0]);
                    }
                  }}
                  defaultOptions={options.templates}
                  id="select-template"
                  disabled={isTemplateDisabled}
                />
              </FormGroup>
            )}

            <div
              className="form-group"
              style={{
                position: 'relative',
                zIndex: 2,
              }}
            >
              <Label for="drag-list">{t('edit-position.arrange-process-steps')} *</Label>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="pipeline-steps-droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={{
                        marginBottom: snapshot.isDraggingOver ? 71 : 0,
                      }}
                    >
                      {!!pipelineSteps?.some((item) => item.name) &&
                        pipelineSteps?.map((pipeline, index) => (
                          <Draggable key={pipeline.uuid} draggableId={pipeline.uuid} index={index}>
                            {(provided) => (
                              <div
                                data-testid={`position-pipeline-step-${index}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="card position-form-draggable-card"
                              >
                                <CardHeader
                                  onClick={() => {
                                    setOpenedItems((currentState) => {
                                      if (currentState.find((item) => item === pipeline.uuid)) {
                                        return currentState.filter((item) => item !== pipeline.uuid);
                                      }

                                      return [...currentState, pipeline.uuid];
                                    });
                                  }}
                                  className="d-flex align-items-center justify-content-between"
                                >
                                  <div className="flex items-center gap-x-1">
                                    <Icon type="fas" name="grip-vertical" className="flex-grow-0" />
                                    {pipeline.name}
                                  </div>
                                  <Icon type="fas" name="sort-down" className="flex-grow-0" style={{ marginTop: -5 }} />
                                </CardHeader>
                                {!!openedItems.find((item) => item === pipeline.uuid) && (
                                  <CardBody className="pt-0">
                                    <PipelineStepForm
                                      pipelineSteps={state?.pipelineSteps}
                                      card={false}
                                      draft={state?.draft}
                                      namePrefix={`pipeline-step-${index}`}
                                      form={pipeline}
                                      onSubmit={onSave}
                                      recipientsList={sriRecipients}
                                      isPositionForm
                                      buttonProps={{
                                        cancelLabel: t('general.delete'),
                                        cancelCallback: () => {
                                          setState(
                                            'pipelineSteps',
                                            pipelineSteps.filter(({ uuid }) => uuid !== pipeline.uuid)
                                          );
                                        },
                                        cancelProps: {
                                          outline: true,
                                        },
                                      }}
                                      index={index}
                                    />
                                  </CardBody>
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <FormGroup
              style={{
                position: 'relative',
                zIndex: 1,
              }}
            >
              {!addStep && (
                <div
                  className="question-adder"
                  data-testid="pipeline-steps-queston-add-button"
                  onClick={() => {
                    setAddStep(true);
                  }}
                >
                  <p className="faded">
                    <Icon name="plus" />
                    {t('general.add-step')}
                  </p>
                </div>
              )}
              {addStep && (
                <PipelineStepForm
                  form={{
                    ...form,
                    isCreate: true,
                  }}
                  draft={state?.draft}
                  pipelineSteps={state?.pipelineSteps}
                  namePrefix="pipeline-step-new"
                  onSubmit={onSave}
                  recipientsList={sriRecipients}
                  isPositionForm
                  buttonProps={{
                    cancelLabel: t('general.cancel'),
                    cancelCallback: () => {
                      setAddStep(false);
                    },
                    cancelProps: {
                      outline: true,
                    },
                  }}
                  index={state?.pipelineSteps?.length || 1}
                />
              )}
            </FormGroup>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PipelineSteps;

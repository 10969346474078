import { useParams } from 'react-router-dom';
import ApiRequestProvider, {
  useApiRequestContext,
} from '../../../context/@v2/ApiRequestContext';
import useAssessment from '../../../hooks/useAssessment';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { getAllTests, getEvaluations } from '../../../store/api/candidates.api';
import { getAlvaLabsConfig } from '../../../store/api/client-integrations.api';

const CandidateTests = ({ children }) => {
  const { id } = useParams();
  const { reloadData } = useApiRequestContext();

  useDidMountEffect(() => {
    reloadData();
  }, [id]);

  return children;
};

const TestWrapper = ({ children }) => {
  const { id } = useParams();

  const { hasAlvaLabs, hasHubert } = useAssessment();

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, token) => {
        const [testResults] = await getAllTests(id, token);
        const evaluationResults = await getEvaluations(id);

        let alvaLabsConfig = null;
        if (hasAlvaLabs) {
          alvaLabsConfig = await getAlvaLabsConfig();
        }

        let hubertConfig = null;
        if (hasHubert) {
          hubertConfig = await getHubertConfig();
        }

        return {
          testResults,
          alvaLabsConfig,
          hubertConfig,
          evaluationResults: evaluationResults || [],
        };
      }}
    >
      <CandidateTests>{children}</CandidateTests>
    </ApiRequestProvider>
  );
};

export default TestWrapper;

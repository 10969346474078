/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';
import qs from 'query-string';
import { get, isArray, uniqBy, omit, cloneDeep } from 'lodash';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import { NotificationManager } from 'react-notifications';
import { useAPI } from '../../context/api';
import { setPositionsEdited, setSyncedRecipientList } from '../../store/slices/settings.slice';
import Layout from '../../components/layouts/default';
import PositionForm from '../../components/forms/position/Position';
import ScreeningForm from '../../components/forms/position/Screening';
import PipelineStepsForm from '../../components/forms/position/PipelineSteps';
import PromoteForm from '../../components/forms/position/Promote';
import PositionFormSubmit from '../../components/positions/FormSubmit';
import { BottomMenuItems, defaultFormData } from '../../constants/Position';
import { RefappAutomationMailOptions, RefappRefereesOptions } from '../../constants/ClientIntegration';
import Icon from '../../components/@v2/Icon';
import CustomButton from '../../components/Button';
import { createLinkSuffix, openModalLink, isValidHttpOrMailtoURL, log } from '../../helpers/utils';
import '../../assets/sass/position-form.sass';
import '../../assets/sass/form-action.sass';
import WithData from '../../components/WithData';
import { devices } from '../../constants/Devices';
import events from '../../helpers/events';
import { getScreeningTypeById } from '../../helpers/screening-criteria';
import { formatQuestions } from '../../helpers/form';
import { setClient } from '../../store/slices/client.slice';
import { selectUser } from '../../store/selectors/user.selector';
import { setPipelineTemplates, setPipelineSteps, setAmsGroups } from '../../store/thunks/settings.thunks';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../store';
import { selectClientConfig } from '../../store/selectors/client.selector';
import {
  selectAlvaLabsProfiles,
  selectHubertInterviewTemplates,
  selectOptions,
  selectRefappQuestionaires,
  selectSriOrderers,
  selectSriRecipients,
} from '../../store/selectors/settings.selector';
import { getAllPipelineSteps, getPipelineTemplate } from '../../store/api/pipelines.api';
import useAssessment from '../../hooks/useAssessment';
import { useSelectClient } from '../../hooks/useSelectClient';
import { getClient } from '../../store/thunks/client.thunk';
import { generatePreviewPosition } from '../../store/api/positions.api';

const defaultPipelineStepsAmount = 2;

const PositionFooter = styled.div`
  z-index: 200;

  @media ${devices.sm} {
    justify-content: center !important;
  }

  @media ${devices.lg} {
    justify-content: space-between !important;
  }
`;

const FooterOL = styled.ol`
  @media ${devices.sm} {
    order: 1;
    width: 100%;
    margin-top: 10px !important;
  }

  @media ${devices.lg} {
    order: 0;
    width: auto;
    margin-bottom: 0 !important;
    flex: 1;
  }
`;

const actionPosition = () => {
  const { t } = useTranslation();
  const { type, id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const options = useSelectorToolkit(selectOptions);
  const queryString = qs.parse(location.search);
  const state = {
    viewState: null,
    ...location.state,
  };
  const [positionAPI, productsAPI, screeningCriteriaAPI] = useAPI('position', 'products', 'screeningCriteriaV2');
  const [isFormSending, setIsFormSending] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentView, setCurrentView] = useState(1);
  const currentUser = useSelectorToolkit(selectUser);
  const [isValidating, setIsValidating] = useState(false);
  const activeClientConfig = useSelectorToolkit(selectClientConfig);
  const sriRecipients = useSelectorToolkit(selectSriRecipients);
  const sriOrderers = useSelectorToolkit(selectSriOrderers);
  const refappQuestionaires = useSelectorToolkit(selectRefappQuestionaires);
  const alvaLabsProfiles = useSelectorToolkit(selectAlvaLabsProfiles);
  const hubertInterviewTemplates = useSelectorToolkit(selectHubertInterviewTemplates);
  const [questionsOptions, setQuestionsOptions] = useState([]);
  const [screeningTypes, setScreeningTypes] = useState({});
  const [loading, setLoading] = useState({
    data: type === 'edit',
    products: true,
  });
  const positionsEdited = useSelectorToolkit(({ settings }) => get(settings, 'positionsEdited')) || [];
  const [data, setData] = useState({
    ...defaultFormData,
    recruiterId: currentUser.activeUserId,
    recruiterData: currentUser,
  });

  const cartProducts = useSelectorToolkit(({ cart }) => cart);
  const [isHandlingPreviewLoading, setIsHandlingPreviewLoading] = useState(false);
  const { selectClientId } = useSelectClient();

  useEffect(() => {
    document.body.classList.add('position-page');

    return () => {
      document.body.classList.remove('position-page');
    };
  }, []);

  const assessment = useAssessment();
  const { assessmentsIds } = assessment;

  useEffect(() => {
    if (queryString.currentView) {
      setCurrentView(parseInt(queryString.currentView, 10));
      history.push({
        ...location,
        state: {
          ...location.state,
          viewState: 4,
        },
        search: '',
      });
    }
  }, [queryString]);

  const setKey = (key, value) => {
    const nextState = {
      ...state,
      [key]: value,
    };

    if (JSON.stringify(nextState) !== JSON.stringify(state)) {
      setData((state) => ({
        ...state,
        [key]: value,
      }));
    }
  };

  const setStateData = (payload) => {
    setData({
      ...payload,
      publishDate: payload.publishDate ? new Date(payload.publishDate) : new Date(),
      applicationDeadline: payload.applicationDeadline ? new Date(payload.applicationDeadline) : null,
      loading: false,
      positionTypeId: 0,
    });
  };

  const getPromotedProducts = async () => {
    try {
      const response = await productsAPI.getShopProducts({
        filters: 'Promote==true,isActive==true',
        sorts: '-id',
        pageSize: -1,
      });

      if (response) setProducts(response.items);
    } catch (error) {
      log('error', error);
    } finally {
      setLoading((state) => ({
        ...state,
        products: false,
      }));
    }
  };

  const getName = useMemo(() => data.name || '', [data]);

  const isAlternativeApplyFormEnabled = !!(data?.useAlternativeApplyForm && data?.alternativeApplyFormLink !== '');

  // check if there's a matching assigned user to current recipients
  const syncedRecipientList = useMemo(() => {
    if (data.recruiterData && sriRecipients && sriRecipients.length) {
      const recruiterData = state?.recruiterData ? state.recruiterData : data.recruiterData;
      const teamMembersData = state?.teamMembers ? state.teamMembers : data.teamMembers;

      const currentlyAssignedUsers = [recruiterData || {}, ...(teamMembersData || [])].map(({ email }) => email);

      const assignedUsers = sriRecipients.filter((item) => currentlyAssignedUsers.includes(item.label));

      return assignedUsers;
    }
    return null;
  }, [data.recruiterData, state?.recruiterData, state?.teamMembers, data.teamMembers, sriRecipients]);

  const resolveOrdererUser = (sriOrdererUserId) => {
    let ordererUser = {};
    if (typeof sriOrdererUserId === 'string') {
      ordererUser = sriOrderers?.filter(({ id }) => sriOrdererUserId.includes(id))[0];
    } else if (typeof sriOrdererUserId === 'object') {
      ordererUser = sriOrdererUserId;
    }

    // To support both api response and form data
    return {
      id: ordererUser?.id || ordererUser?.userId,
      email: ordererUser?.label || ordererUser?.email,
      label: ordererUser?.label || ordererUser?.email,
      userId: ordererUser?.id || ordererUser?.userId,
    };
  };

  const resolveRecipientUsers = (sriRecipientUserIds) => {
    if (sriRecipientUserIds && sriRecipientUserIds?.length) {
      const isArrayOfObjectUsers = !!sriRecipientUserIds[0]?.id || !!sriRecipientUserIds[0]?.userId;

      if (isArrayOfObjectUsers) {
        return sriRecipientUserIds.map(({ id, label, userId, email }) => ({
          id: userId || id,
          label: label || email,
          // this is the current value from BE
          userId: userId || id,
          email: label || email,
        }));
      }
      return sriRecipients.filter(({ id }) => sriRecipientUserIds.includes(id));
    }
    return syncedRecipientList;
  };

  const resolveAlvaLabsTestProfileId = (alvaLabsTestProfileId) => {
    let profileId = {};

    if (typeof alvaLabsTestProfileId === 'string') {
      profileId = alvaLabsProfiles?.filter(({ id }) => alvaLabsTestProfileId.includes(id))[0];
    } else if (typeof alvaLabsTestProfileId === 'object') {
      profileId = alvaLabsTestProfileId;
    }

    // To support both api response and form data
    return profileId;
  };

  const resolveHubertInterviewTemplateId = (hubertInterviewTemplateId) => {
    let interviewId = {};
    if (typeof hubertInterviewTemplateId === 'string') {
      interviewId = hubertInterviewTemplates?.filter(({ id }) => hubertInterviewTemplateId.includes(id))[0];
    } else if (typeof hubertInterviewTemplateId === 'object') {
      interviewId = hubertInterviewTemplateId;
    }

    // To support both api response and form data
    return interviewId;
  };

  const resolveRefappQuestionaireId = (refappQuestionerId) => {
    let questionaireId = {};

    if (typeof refappQuestionerId === 'string') {
      questionaireId = refappQuestionaires?.filter(({ id }) => refappQuestionerId.includes(id))[0];
    } else if (typeof refappQuestionerId === 'object') {
      questionaireId = refappQuestionerId;
    }

    // To support both api response and form data
    return questionaireId;
  };

  useEffect(() => {
    dispatchToolkit(setSyncedRecipientList(syncedRecipientList));
  }, [syncedRecipientList]);

  const canGoNext = useMemo(() => {
    if (currentView === 1) {
      const emptyJobFunc = (data.jobFunction && data.jobFunction.length === 0) || !data.jobFunction;
      const isFileLargerThan5mb = get(data, 'image.size') / 1000 > 5000;

      if (
        data.name === '' ||
        !data.recruiterId ||
        !data.locationId ||
        (emptyJobFunc && !data.spontaneous) ||
        data.publishDate === null ||
        (data.pitch && data.pitch.length > 200) ||
        isFileLargerThan5mb ||
        (data.useAlternativeApplyForm === true && !isValidHttpOrMailtoURL(data.alternativeApplyFormLink))
      ) {
        return false;
      }
    } else if (currentView === 2 && !isAlternativeApplyFormEnabled) {
      return data?.pipelineSteps?.length >= defaultPipelineStepsAmount;
    }

    return true;
  }, [currentView, data, isValidHttpOrMailtoURL, isAlternativeApplyFormEnabled]);

  const canBeSavedAsDraft = useMemo(
    () => type === 'add' || !!data.draft || !!data.archived,
    [type, data.draft, data.archived]
  );

  const isAdding = useMemo(() => type === 'add' && data.positionId === 0, [type, data.positionId]);

  const isPreselected = useMemo(() => type === 'add' && state.selectedTemplate, [type, state.selectedTemplate]);

  const goToView = (view) => {
    setCurrentView(view);
  };

  const hasMatchedProduct = useMemo(
    () =>
      (type === 'add' && cartProducts && cartProducts.length) ||
      (type === 'edit' &&
        cartProducts &&
        cartProducts.length &&
        cartProducts.filter(({ positionId }) => parseInt(id, 10) === positionId).length),
    [cartProducts, id]
  );

  const hasPromotePermission = useMemo(() => {
    const permissionLevel = get(currentUser, 'permissionLevel');
    const clientSettings = get(activeClientConfig, 'integrationSettings');

    if (clientSettings) {
      if (!products.length) {
        return false;
      }
      let hasPermission;
      const { showPublishOnAms, showRekryShop } = clientSettings;
      switch (showRekryShop) {
        case 0:
          hasPermission = showPublishOnAms;
          break;
        case 1:
          hasPermission = true;
          break;
        case 2:
          hasPermission = permissionLevel > 0;
          break;
        default:
          hasPermission = false;
          break;
      }

      return hasPermission;
    }
    return false;
  }, [activeClientConfig, currentUser, products]);

  const nextBtnText = useMemo(() => {
    if (
      currentView === 3 &&
      !get(activeClientConfig, 'integrationSettings.showPublishOnAms') &&
      get(activeClientConfig, 'integrationSettings.showRekryShop') === 0
    ) {
      return t('general.publish');
    }

    if (currentView === 4 || (currentView === 2 && isAlternativeApplyFormEnabled)) {
      if (hasMatchedProduct) return t('general.review-and-publish');
      return t('general.publish');
    }

    return t('positions.next-step');
  }, [hasMatchedProduct, isAlternativeApplyFormEnabled, currentView]);

  const isPublishSection = useMemo(() => {
    return (
      (currentView === 3 &&
        !get(activeClientConfig, 'integrationSettings.showPublishOnAms') &&
        get(activeClientConfig, 'integrationSettings.showRekryShop') === 0) ||
      currentView === 4
    );
  }, [currentView]);

  const uploadFile = async (type, file, id, updateState, imageCoords) => {
    try {
      const lowercaseType = type.toLowerCase();
      const formData = new FormData();
      formData.append(lowercaseType, file);
      if (imageCoords) {
        formData.append('focusPointJsonString', JSON.stringify(imageCoords));
      }

      const response = await positionAPI[`upload${type}`](id, formData);

      if (updateState) {
        setStateData({
          ...data,
          [lowercaseType]: response[lowercaseType],
          positionId: id,
        });
      }

      return response;
    } catch (error) {
      // log(error);
    }

    return null;
  };

  const uploadImages = async ({ picture, image, positionId, imageCoords }, updateState = true) => {
    let p = {};
    let i = {};

    if (picture && picture.lastModified) {
      p = await uploadFile('Picture', picture, positionId, updateState);
    }

    if (image && image.lastModified) {
      i = await uploadFile('Image', image, positionId, updateState, imageCoords || null);
    }

    return { p, i };
  };

  const isOnCareerPage = () => {
    const isAfterPublishDate = moment().isSameOrAfter(data.publishDate || new Date());
    const isBeforeDeadlineDate = moment().isSameOrBefore(data.applicationDeadline || new Date());
    return isAfterPublishDate && isBeforeDeadlineDate;
  };

  const pipelineSteps = useMemo(() => {
    const parsedPipelineSteps = cloneDeep(data?.pipelineSteps || []);
    return parsedPipelineSteps?.map((item) => {
      return omit(
        {
          ...item,
          id: item.added ? 0 : item.id,
          icon: get(item, 'icon.value') || item?.icon,
          refappQuestionerId: resolveRefappQuestionaireId(
            get(item?.refappQuestionerId, 'value') || item?.refappQuestionerId
          ),
          assessmentType: item?.assessmentType,
          alvaLabsTestProfileId: resolveAlvaLabsTestProfileId(
            get(item?.alvaLabsTestProfileId, 'value') || item?.alvaLabsTestProfileId
          ),
          hubertInterviewTemplateId: resolveHubertInterviewTemplateId(
            get(item?.hubertInterviewTemplateId, 'value') || item?.hubertInterviewTemplateId
          ),
          refereeMailRequestMethodType:
            get(item?.refereeMailRequestMethodType, 'value') ||
            item?.refereeMailRequestMethodType ||
            RefappAutomationMailOptions(t)[0],
          referees: get(item?.referees, 'value') || item?.referees || RefappRefereesOptions[2],
          refereeMailReferenceMethodType:
            get(item?.refereeMailReferenceMethodType, 'value') ||
            item?.refereeMailReferenceMethodType ||
            RefappAutomationMailOptions(t)[0],
          recipientUsers:
            item?.sriRecipientUserIds?.length && item?.sriRecipientUserIds?.[0]?.id
              ? item?.sriRecipientUserIds?.map(({ label, id }) => {
                  return {
                    id,
                    email: label,
                  };
                })
              : sriRecipients.filter((recipient) => (item?.sriRecipientUserIds || []).includes(recipient.id)),
          ordererUser: resolveOrdererUser(item?.sriOrdererUserId),
          sriOrdererUserId: resolveOrdererUser(item?.sriOrdererUserId),
        },
        ['test', 'integrationType']
      );
    });
  }, [data?.pipelineSteps, data.positionId, sriOrderers, sriRecipients]);

  const getNewImageObject = (image, imageCoords) => {
    if (!get(image, 'lastModified', false)) {
      if (imageCoords) {
        return {
          ...image,
          focusPointJsonString: JSON.stringify(imageCoords),
        };
      }

      return image;
    }

    return {
      fileExt: '',
      focusPointJsonString: JSON.stringify(imageCoords),
      guid: '',
      name: '',
      path: '',
    };
  };

  const updatedJobFunction = useMemo(() => {
    let jobFunction = get(data, 'jobFunction') || '';

    if (isArray(jobFunction)) {
      if (jobFunction[0]) {
        jobFunction = jobFunction[0];
      } else {
        jobFunction = '';
      }
    }

    return jobFunction;
  }, [get(data, 'jobFunction')]);

  const formatDate = (date) => {
    if (date) {
      return moment(date).format('YYYY-MM-DD');
    }

    return null;
  };

  const setFormData = (payload = {}) => {
    const {
      name,
      picture,
      image,
      imageCoords,
      publishDate,
      applicationDeadline,
      publicLinkSuffix,
      categorySettingsAJsonString,
      categorySettingsBJsonString,
      categorySettingsCJsonString,
      categorySettingsOthersJsonString,
      questionsJsonString,
      addedQuestions,
      teamMembers,
    } = data;

    const getNotifiedUserIdString = (str, candidateCategoryId) => {
      const parsedJson = JSON.parse(str);

      if (parsedJson.notifiedUserIdJsonString) {
        const parsedNotifiedUserId = JSON.parse(parsedJson.notifiedUserIdJsonString);
        return JSON.stringify({
          ...parsedJson,
          candidateCategoryId,
          notifiedUserIdJsonString: JSON.stringify(parsedNotifiedUserId.map((item) => item.userId || item)),
        });
      }

      return str;
    };

    // FE checkers to see if questions aldy added are active
    let criterias = [];

    JSON.parse(questionsJsonString || '[]').forEach((parentItem) => {
      const foundQuestion = questionsOptions.find(
        (question) => question.screeningCriteriaId === parentItem.screeningCriteriaId
      );

      if (foundQuestion) {
        const screeningType = getScreeningTypeById(foundQuestion.screeningCriteriaTypeId, screeningTypes);
        criterias = [
          ...criterias,
          {
            ...foundQuestion,
            ...parentItem,
            type: screeningType,
            selections: foundQuestion.selections.map((item) => ({
              ...item,
              value: item.screeningCriteriaId,
              label: item.name,
            })),
          },
        ];
      }
    });

    if (addedQuestions && addedQuestions.length) {
      // if new question is added alongside inactive question
      criterias = [...addedQuestions, ...criterias];
    }

    return {
      ...data,
      positionTypeId: null,
      draft: currentView === 3 && isAdding,
      publishDate: formatDate(publishDate),
      applicationDeadline: formatDate(applicationDeadline),
      picture: picture && picture.lastModified ? null : picture,
      image: getNewImageObject(image, imageCoords),
      publicLinkSuffix: !publicLinkSuffix ? createLinkSuffix(name) : publicLinkSuffix,
      categorySettingsAJsonString: getNotifiedUserIdString(categorySettingsAJsonString, 1),
      categorySettingsBJsonString: getNotifiedUserIdString(categorySettingsBJsonString, 2),
      categorySettingsCJsonString: getNotifiedUserIdString(categorySettingsCJsonString, 3),
      categorySettingsOthersJsonString: getNotifiedUserIdString(categorySettingsOthersJsonString, 0),
      ...payload,
      pipelineSteps,
      jobFunction: updatedJobFunction,
      description: data.description ? data.description.replace(/<p><br><\/p>/g, '<br>') : '',
      questionsJsonString:
        type === 'add' ? questionsJsonString : JSON.stringify(uniqBy(criterias, 'screeningCriteriaId')),
      teamMembers: teamMembers.map((item) => {
        return {
          ...item,
          id: item?.userId || item?.id || item?.teamMemberId,
        };
      }),
    };
  };

  const adjustPipelinesteps = (existing) => {
    return (existing || []).map((item) => {
      return {
        ...item,
        alvaLabsTestProfileId: resolveAlvaLabsTestProfileId(item.assessment?.alvaLabsTestProfileId),
        hubertInterviewTemplateId: resolveHubertInterviewTemplateId(item.assessment?.hubertInterviewTemplateId),
        refappQuestionerId: resolveRefappQuestionaireId(item.assessment?.refappQuestionerId),
        refereeMailReferenceMethodType: item.assessment?.refereeMailReferenceMethodType
          ? RefappAutomationMailOptions(t).find(
              ({ value }) => value === item.assessment?.refereeMailReferenceMethodType.toString()
            )
          : RefappAutomationMailOptions(t)[0],
        refereeMailRequestMethodType: item.assessment?.refereeMailRequestMethodType
          ? RefappAutomationMailOptions(t).find(
              ({ value }) => value === item.assessment?.refereeMailRequestMethodType.toString()
            )
          : RefappAutomationMailOptions(t)[0],
        referees:
          item.assessment?.referees && item.assessment?.referees !== 0
            ? RefappRefereesOptions.find(({ value }) => value === item.assessment?.referees.toString())
            : RefappRefereesOptions[2],
        sriRecipientUserIds: resolveRecipientUsers(
          item.assessment?.sriRecipientUsers || item.assessment?.sriRecipientUserIds
        ),
        sriOrdererUserId: resolveOrdererUser(item.assessment?.sriOrdererUser || item.assessment?.sriOrdererUserId),
        sriOrdererUserEmail: resolveOrdererUser(item.assessment?.sriOrdererUser)?.email || '',
        assessmentType: item.assessment?.type || null,
      };
    });
  };

  const handleSubmitForm = async (payload = {}, hideModal = false, goDirectlyToCheckout = false) => {
    if (isFormSending) return false;

    try {
      setIsFormSending(true);
      let apiKey = `${isAdding ? 'add' : 'update'}Position`;
      let formData = setFormData(payload);

      formData = {
        ...formData,
        pipelineSteps: formData.pipelineSteps.map((item) => {
          let assessment = null;
          let assessmentType = item?.assessmentType?.value || item?.assessmentType || null;

          if (item.refappQuestionerId?.id) {
            assessmentType = assessmentsIds.refapp;
          } else if (item.alvaLabsTestProfileId?.id) {
            assessmentType = assessmentsIds.alvaLabs;
          } else if (item.hubertInterviewTemplateId?.id) {
            assessmentType = assessmentsIds.hubert;
          } else if (item.hubertInterviewTemplateId?.id) {
            assessmentType = assessmentsIds.hubert;
          } else if (item.sriOrdererUserId?.id) {
            assessmentType = assessmentsIds.sri;
          }

          if (assessmentType) {
            // TODO: add a func for setting values here regardless if value is directly from item or from item?.assessement
            // or refactor this entirely as payload from BE is nested and regularly changes
            assessment = {
              type: assessmentType,
              alvaLabsTestProfileId: resolveAlvaLabsTestProfileId(item.alvaLabsTestProfileId)?.id,
              hubertInterviewTemplateId: resolveHubertInterviewTemplateId(item.hubertInterviewTemplateId)?.id,
              refappQuestionerId: resolveRefappQuestionaireId(item.refappQuestionerId)?.id,
              refereeMailReferenceMethodType: parseInt(
                get(item?.refereeMailReferenceMethodType, 'value') || item?.refereeMailReferenceMethodType,
                10
              ),
              referees: parseInt(get(item?.referees, 'value') || item?.referees, 10),
              refereeMailRequestMethodType: parseInt(
                get(item?.refereeMailRequestMethodType, 'value') || item?.refereeMailRequestMethodType,
                10
              ),
              recipientUsers: resolveRecipientUsers(
                item?.sriRecipientUserIds?.length && item?.sriRecipientUserIds?.[0].id
                  ? item?.sriRecipientUserIds
                  : item?.assessment?.sriRecipientUserIds
              ),
              ordererUser: resolveOrdererUser(
                item?.sriOrdererUserId?.id ? item?.sriOrdererUserId : item?.assessment?.sriOrdererUserId
              ),
            };
          }

          return omit(
            {
              ...item,
              id: item.id || 0,
              deleted: item.deleted || false,
              positionId: null,
              clientId: selectClientId,
              assessment,
            },
            [
              'assessmentType',
              'alvaLabsTestProfileId',
              'hubertInterviewTemplateId',
              'refappQuestionerId',
              'refereeMailReferenceMethodType',
              'referees',
              'refereeMailRequestMethodType',
              'sriRecipientUserIds',
              'sriOrdererUserEmail',
            ]
          );
        }),
        teamMemberIds: formData.teamMembers.map((item) => item.id),
      };

      Reflect.deleteProperty(formData, 'pictureInfo');
      Reflect.deleteProperty(formData, 'imageInfo');

      if (type === 'add') {
        formData = {
          ...formData,
          questionsJsonString: JSON.stringify(formatQuestions(JSON.parse(formData.questionsJsonString))),
        };
      }

      if (apiKey === 'updatePosition') {
        if (get(formData, 'positionId') === 0) {
          return false;
        }
        if (formData.draft) {
          apiKey = 'saveDraftPosition';
        } else {
          apiKey = formData.publishDate ? 'republish' : 'publishPosition';
        }
      }

      if (type === 'add' && isPublishSection) {
        if (get(formData, 'positionId') !== 0) {
          apiKey = 'publishPosition';
        }
      }

      const response = await positionAPI[apiKey](apiKey === 'republish' ? { form: formData } : formData);

      if (response && typeof response === 'number') {
        const position = await positionAPI.getPosition(response);
        if (position) {
          setStateData({
            ...position,
            pipelineSteps: adjustPipelinesteps(position?.pipelineSteps),
          });

          dispatchToolkit(
            setPositionsEdited(positionsEdited.filter(({ positionId }) => positionId !== formData.positionId))
          );

          if (!hideModal || goDirectlyToCheckout) {
            const isGettingPublished = isPublishSection;
            if (!position.draft) {
              NotificationManager.success(t('general.position-is-updated'));
              if (currentView === 3 && !get(activeClientConfig, 'integrationSettings.showPublishOnAms')) {
                history.push({
                  ...location,
                  search: qs.stringify({
                    modal: 'position-published',
                    positionId: position.positionId,
                    urlText: position.publicLinkSuffix,
                    activeClientConfigKey: activeClientConfig.key,
                    isPublished: isOnCareerPage(),
                    isGettingPublished,
                  }),
                });
              } else if (hasMatchedProduct) {
                history.push('/checkout');
              } else {
                openModalLink(
                  {
                    modal: 'position-published',
                    positionId: position.positionId,
                    urlText: position.publicLinkSuffix,
                    activeClientConfigKey: activeClientConfig.key,
                    isPublished: isOnCareerPage(),
                    isGettingPublished,
                  },
                  {
                    ...location,
                    state: {
                      ...location.state,
                      viewState: 4,
                      fromOrder: 4,
                    },
                  },
                  history
                );
              }
            } else {
              NotificationManager.success(t('positions.draft-saved'));
            }
          }

          return position;
        }

        return null;
      }
    } catch (error) {
      log(error);
      return null;
    } finally {
      setIsFormSending(false);
    }

    return true;
  };

  const handlePreview = async () => {
    setIsValidating(true);
    if (!canGoNext) return;

    const getImage = (imageItem, coords = null) => {
      const item = imageItem;

      if (coords) {
        item.focusPointJsonString = JSON.stringify(coords);
      }

      return item;
    };

    try {
      const imageData = getImage(data.image);
      const pictureData = data.picture || null;

      // https://dev.azure.com/ts-dev0246/TalentSoftware/_workitems/edit/1996
      // const draftPosition = await handleSubmitForm({ draft: canBeSavedAsDraft }, true);

      const payload = {
        ...omit(data, ['imageCoords']),
        preview: true,
        image: data.image ? imageData : null,
        picture: data.picture ? pictureData : null,
        imageInput: data.imageInput ? getImage(data.imageInput, data.imageCoords) : null,
        pictureInput: data.pictureInput ? getImage(data.pictureInput) : null,
        positionTypeId: 1,
        jobFunction: updatedJobFunction,
        applicationDeadline: data.applicationDeadline
          ? moment(data.applicationDeadline).format('YYYY-MM-DDTHH:mm:ss')
          : null,
        description: data.description ? data.description.replace(/<p><br><\/p>/g, '<br>') : '',
        positionId: data.positionId,
      };

      Reflect.deleteProperty(payload, 'pictureInfo');
      Reflect.deleteProperty(payload, 'imageInfo');

      if (type === 'add') {
        Reflect.deleteProperty(payload, 'image');
        Reflect.deleteProperty(payload, 'picture');
      }

      const response = await generatePreviewPosition(payload.positionId, { form: payload });

      if (response) {
        const positionId = response;

        await uploadImages(
          {
            positionId,
            picture: data.picture,
            image: data.image,
            imageCoords: JSON.stringify(data.imageCoords),
          },
          false
        );

        const win = window.open(`${global?.appConfig?.api_url || ''}/positions/${positionId}/preview`, '_blank');
        if (win != null) win.focus();
      }
    } catch (error) {
      // log('error', error);
    }
  };
  const handlePreviewFn = async () => {
    if (isHandlingPreviewLoading) return;

    try {
      setIsHandlingPreviewLoading(true);
      await handlePreview();
    } catch (error) {
      log(error);
    } finally {
      setIsHandlingPreviewLoading(false);
    }
  };

  useEffect(() => {
    if (currentView === 4 && !isAdding) {
      const mappedPositions = [...(positionsEdited || []), data].map((item) => {
        if (item.positionId === data.positionId) {
          item = setFormData();
        }

        return item;
      });
      dispatchToolkit(setPositionsEdited(uniqBy(mappedPositions, 'positionId')));
    }
  }, [currentView, isAdding]);

  useEffect(() => {
    if (id) {
      goToView(1);
    }
  }, [id]);

  const pipelineStepsOnLoad = React.useRef(false);

  const getTests = async (pipelineSteps = []) => {
    try {
      const steps = pipelineSteps?.map((item) => {
        if (!assessment.testIds.includes(item.assessmentType)) {
          return {
            ...item,
            testId: null,
          };
        }

        return item;
      });

      const newPipelines = state?.selectedTemplate && pipelineSteps.length >= 2 ? pipelineSteps : steps;
      setKey('pipelineSteps', newPipelines);
    } catch (error) {
      log(error);
    }
  };

  const getDefaultPipelines = async (templateId) => {
    const response = await getPipelineTemplate(templateId);
    if (response) {
      return response?.pipelineSteps;
    }
    return [];
  };

  useEffect(() => {
    if (!pipelineStepsOnLoad?.current) {
      if (
        type === 'add' &&
        (!data?.pipelineSteps?.length || data?.pipelineSteps?.length < defaultPipelineStepsAmount)
      ) {
        // need a source for supplying at least two default pipeline steps
        let defaultPipelines = [];
        if (state.selectedTemplate) {
          const modifyCategory = (categorySetting = {}, categoryNum) => {
            const mappedCategory = {
              active: false,
              sendMailToCandidate: false,
              candidateEmailSubject: '',
              candidateEmailText: '',
              candidateEmailDelayHours: 0,
              autoRejectCandidate: false,
              ...categorySetting,
              id: categorySetting.id || 0,
              notifiedUserIdJsonString: categorySetting.notifiedUserIdJsonString || '[]',
              candidateCategoryId: categoryNum,
            };
            return JSON.stringify(mappedCategory);
          };

          const {
            positionName,
            pipelineSteps,
            pitch,
            criterias,
            categorySettingsA,
            categorySettingsB,
            categorySettingsC,
            categorySettingsOthers,
            imageUrl,
            pictureUrl,
            id,
          } = state.selectedTemplate;

          const mappedPipelines =
            pipelineSteps?.length && pipelineSteps?.length >= defaultPipelineStepsAmount
              ? pipelineSteps?.map((item) => {
                  const tempId = uuidv4();
                  const isAdded = !item.id || !item.pipelineStepId || !item.userId;
                  return {
                    ...item,
                    added: isAdded,
                    id: item.id || item.userId || tempId,
                    pipelineStepId: item.pipelineStepId || tempId,
                    uuid: tempId,
                  };
                })
              : [];

          const selectedTemplateData = {
            ...data,
            ...state.selectedTemplate,
            positionTemplateId: id,
            name: positionName,
            pitch,
            pipelineSteps: adjustPipelinesteps(mappedPipelines),
            questionsJsonString: JSON.stringify(criterias),
            categorySettingsAJsonString: modifyCategory(categorySettingsA || {}, 1),
            categorySettingsBJsonString: modifyCategory(categorySettingsB || {}, 2),
            categorySettingsCJsonString: modifyCategory(categorySettingsC || {}, 3),
            categorySettingsOthersJsonString: modifyCategory(categorySettingsOthers || {}, 0),
            imageInfo: imageUrl || null,
            pictureInfo: pictureUrl || null,
          };

          setData(
            omit(selectedTemplateData, [
              'id',
              'criterias',
              'categorySettingsA',
              'categorySettingsB',
              'categorySettingsC',
              'categorySettingsOthers',
              'imageUrl',
              'pictureUrl',
            ])
          );

          (async () => {
            try {
              await getTests(selectedTemplateData.pipelineSteps);
            } catch (error) {
              log('error', error);
            }
          })();
        } else {
          (async () => {
            try {
              if (options?.templates && options?.templates?.length) {
                defaultPipelines = await getDefaultPipelines(options?.templates[0]?.id);
                await getTests(defaultPipelines);
              }
            } catch (error) {
              log('error', error);
            }
          })();
        }

        pipelineStepsOnLoad.current = true;
      }
    }
  }, [type, options.templates, data?.pipelineSteps, state?.selectedTemplate]);

  useEffect(() => {
    events.$on('publish-position', () => {
      handleSubmitForm(state, true, true);
    });

    return () => {
      events.$off('publish-position');
    };
  }, [state]);

  useEffect(() => {
    if (state && state.fromOrder) {
      const currentView = state.fromOrder ? state.fromOrder : 1;
      goToView(currentView);
    }
  }, [state.fromOrder]);

  useEffect(() => {
    if (get(activeClientConfig, 'integrationSettings.showRekryShop')) {
      getPromotedProducts();
    }
    dispatchToolkit(setPipelineTemplates());
    dispatchToolkit(setPipelineSteps());
    dispatchToolkit(setAmsGroups());

    // To workaround drafts not being saved without pipeline steps
    // https://dev.azure.com/ts-dev0246/TalentSoftware/_workitems/edit/2180
    (async () => {
      if (type === 'add' && !data?.pipelineSteps?.length && !state.selectedTemplate) {
        const pipelineSteps = await getAllPipelineSteps();
        setKey(
          'pipelineSteps',
          pipelineSteps && pipelineSteps?.length >= defaultPipelineStepsAmount
            ? pipelineSteps.slice(0, defaultPipelineStepsAmount)
            : pipelineSteps
        );
      }
    })();
  }, [type]);

  const loadScreeningTypes = async () => {
    const types = await screeningCriteriaAPI.getTypes();
    setScreeningTypes(types);
  };

  const getQuestions = async () => {
    try {
      const items = await screeningCriteriaAPI.lookup({
        filters: 'deleted==false',
      });

      setQuestionsOptions(
        items.map((item) => ({
          ...item,
          id: item.screeningCriteriaId,
          value: item.screeningCriteriaId,
          label: item.name,
          selections: item.selections.map((selection, index) => ({ ...selection, value: index + 1 })),
        }))
      );
    } catch (error) {
      // log(error)
    }
  };

  useEffect(() => {
    loadScreeningTypes();
    getQuestions();
  }, []);

  const wrapperDataTestId = (step) => {
    switch (step) {
      case 1:
        return 'position-form-wrapper';
      case 2:
        return 'pipeline-steps-form-wrapper';
      case 3:
        return 'screening-form-wrapper';
      case 4:
        return 'promote-form-wrapper';
      default:
        return '';
    }
  };

  return (
    <Layout
      title={`${isAdding ? t('positions.new') : t('general.edit')} ${t('general.position')} ${
        getName ? ` - ${getName}` : ''
      }`}
      data-testid="position-form"
      headerTextStyle={{
        marginBottom: 10,
        paddingBottom: 0,
      }}
    >
      <WithData
        error={type === 'edit' && !id}
        api={type === 'edit' && id ? () => positionAPI.getPosition(id) : null}
        setItems={async (response) => {
          if (response?.pipelineSteps) {
            await getTests(response.pipelineSteps);
          }

          const client = await dispatchToolkit(
            getClient({
              id: get(currentUser, 'clientId'),
              superAdmin: get(currentUser, 'isSuperAdmin'),
            })
          ).unwrap();

          dispatchToolkit(
            setClient({
              ...client,
              ...activeClientConfig,
            })
          );

          setStateData({
            ...response,
            hasImage: !!response.image,
            hasPicture: !!response.picture,
            pipelineSteps: adjustPipelinesteps(response?.pipelineSteps),
            jobFunction:
              response.jobFunction && Array.isArray(response.jobFunction) && response.jobFunction[0]
                ? response.jobFunction[0]
                : response.jobFunction || '',
            pictureInfo: response.pictureInfo?.blobUrl,
            imageInfo: response.imageInfo?.blobUrl,
            publishToMasterClient: !!response.hidden === false,
            teamMembers: (response.teamMembers || []).map((item) => ({
              id: item.id || item.teamMemberId,
              name: `${item.firstName} ${item.lastName}`,
            })),
          });

          setLoading((state) => ({
            ...state,
            data: false,
          }));
        }}
      >
        {() => (
          <>
            <div className="anim-table-delayed" data-testid={wrapperDataTestId(currentView)}>
              {currentView === 1 && (
                <PositionForm
                  state={{
                    positionId: data.positionId,
                    selectedLocation: data.selectedLocation,
                    publicLinkSuffix: data.publicLinkSuffix,
                    pictureInfo: data.pictureInfo,
                    imageInfo: data.imageInfo,
                    name: data.name,
                    publishDate: data.publishDate,
                    applicationDeadline: data.applicationDeadline,
                    description: data.description,
                    deletePicture: data.deletePicture,
                    picture: data.picture,
                    image: data.image,
                    imageCoords: data.imageCoords,
                    spontaneous: data.spontaneous,
                    hidden: data.hidden,
                    hideAfterDeadline: data.hideAfterDeadline,
                    pitch: data.pitch,
                    locationName: data.locationName,
                    locationId: data.locationId,
                    recruiterId: data.recruiterId,
                    teamMembers: data.teamMembers,
                    jobFunction: data.jobFunction,
                    employmentRate: data.employmentRate,
                    employmentRateDescription: data.employmentRateDescription,
                    departmentId: data.departmentId,
                    showRecruiterInfo: data.showRecruiterInfo,
                    useAlternativeApplyForm: data.useAlternativeApplyForm,
                    showDescription: data?.showDescription,
                    alternativeApplyFormLink: data.alternativeApplyFormLink,
                    publishToMasterClient: data.publishToMasterClient,
                  }}
                  setState={setKey}
                  isValidating={isValidating}
                  loading={loading}
                />
              )}

              {currentView === 2 && !isAlternativeApplyFormEnabled && (
                <PipelineStepsForm
                  state={{
                    positionId: data?.positionId,
                    draft: data?.draft,
                    useAlternativeApplyForm: data?.useAlternativeApplyForm,
                    showDescription: data?.showDescription,
                    pipelineSteps: data.pipelineSteps,
                    recruiterData: data.recruiterData,
                    teamMembers: data.teamMembers,
                    pipelineStepsTemplate: data.pipelineStepsTemplate,
                  }}
                  setState={setKey}
                  isAdding={isAdding}
                  loading={loading}
                  testOptions={assessment.options}
                  isPreselected={isPreselected}
                  adjustPipelinesteps={adjustPipelinesteps}
                />
              )}
              {currentView === 3 && !isAlternativeApplyFormEnabled && (
                <ScreeningForm setState={setKey} state={data} loading={loading} />
              )}
              {(currentView === 4 || (currentView === 2 && isAlternativeApplyFormEnabled)) && (
                <PromoteForm
                  handleSubmitForm={handleSubmitForm}
                  state={{
                    positionId: data.positionId,
                    locationName: data.locationName,
                    publishOnAms: data.publishOnAms,
                    salaryType: data.salaryType,
                    name: data.name,
                    orderItems: data.orderItems,
                    recruiterId: data.recruiterId,
                  }}
                  setState={setKey}
                  products={products}
                  isProductsFetching={loading.products}
                />
              )}
            </div>
            <PositionFooter className="form-position-footer sticky-bottom-menu d-flex align-items-center justify-content-between align-items-center flex-wrap">
              <div className="flex items-center">
                <CustomButton
                  data-testid="position-back-button"
                  id="back-button"
                  color="light"
                  outline
                  type="button"
                  onClick={() => {
                    if (currentView > 1) goToView(currentView - 1);
                  }}
                  disabled={currentView === 1}
                  className="btn-rounded"
                >
                  {t('positions.back')}
                </CustomButton>
                {currentView !== 4 && !isAlternativeApplyFormEnabled && (
                  <PositionFormSubmit
                    canBeSavedAsDraft={canBeSavedAsDraft}
                    handlePreview={handlePreview}
                    saveAsDraft={() => {
                      handleSubmitForm({ draft: canBeSavedAsDraft });
                    }}
                    btnDisabled={isFormSending || !canGoNext}
                  />
                )}
              </div>
              <FooterOL className="flex items-center justify-center">
                {(!isAlternativeApplyFormEnabled
                  ? BottomMenuItems
                  : BottomMenuItems.filter(
                      (item) => item.component === 'VisiblePosition' || item.component === 'Promote'
                    )
                ).map((item, index) => {
                  const viewIndex = index + 1;

                  if (viewIndex === 4 && !hasPromotePermission) return null;

                  return (
                    <li
                      key={item.label}
                      onClick={() => {
                        setIsValidating(true);
                        if (!canGoNext) return;
                        goToView(viewIndex);
                      }}
                      data-testid={`position-step-${viewIndex}`}
                    >
                      <div
                        className={classnames('d-flex align-items-center justify-content-center', {
                          active: viewIndex === currentView,
                        })}
                      >
                        {viewIndex < currentView ? (
                          <svg
                            version="1.1"
                            id="c1"
                            className="checkmark"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 52 52"
                          >
                            <circle className="checkmark--circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark--check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                          </svg>
                        ) : (
                          <Icon name="circle" color="var(--gray-badge)" />
                        )}
                        <span className="number">{viewIndex}. </span>
                        <span className="label">{t(item.label)} </span>
                      </div>
                    </li>
                  );
                })}
              </FooterOL>
              <div className="flex items-center">
                <CustomButton
                  data-testid="position-preview-button"
                  outline
                  color="primary"
                  type="button"
                  onClick={handlePreviewFn}
                  loading={isHandlingPreviewLoading}
                  disabled={isFormSending || !canGoNext}
                  className="form-position-prev-button btn-rounded"
                  id="preview-button"
                >
                  {t('general.preview')}
                </CustomButton>
                <CustomButton
                  data-testid="position-next-button"
                  color="success"
                  type="button"
                  id="next-button"
                  onClick={async () => {
                    setIsValidating(true);
                    let stepsCount = get(activeClientConfig, 'integrationSettings.showRekryShop') ? 4 : 3;

                    if (isAlternativeApplyFormEnabled) {
                      stepsCount = 2;
                    }

                    if (canGoNext) {
                      if (currentView < stepsCount) {
                        goToView(currentView + 1);
                      } else {
                        handleSubmitForm({ draft: false });
                      }
                    }
                  }}
                  disabled={!canGoNext || isFormSending}
                  className="form-position-next-button ml-2 btn-rounded"
                  loading={isFormSending && currentView > 2}
                >
                  {nextBtnText}
                </CustomButton>
              </div>
            </PositionFooter>
          </>
        )}
      </WithData>
    </Layout>
  );
};

export default actionPosition;

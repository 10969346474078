import Assessments from '../constants/Assessments';
import { useSelector as useSelectorToolkit } from '../store';
import { selectClient } from '../store/selectors/client.selector';
import { selectOptions } from '../store/selectors/settings.selector';

const useAssessment = () => {
  const client = useSelectorToolkit(selectClient);
  const { tests } = useSelectorToolkit(selectOptions);
  const selectedTests = tests ? tests || [] : [];
  const assessmentTypes = client?.assessmentTypes || [];
  const testTypes = ['mint', 'service'];
  const hasRefapp = !!assessmentTypes.find(({ label }) => (label || '').toLowerCase() === 'refapp');
  const hasAlvaLabs = !!assessmentTypes.find(({ label }) => (label || '').toLowerCase() === 'alvalabs');
  const hasHubert = !!assessmentTypes.find(({ label }) => (label || '').toLowerCase() === 'hubert');
  const hasSri = !!assessmentTypes.find(({ label }) => (label || '').toLowerCase() === 'sri');
  const hasAssessio = !!assessmentTypes.find(({ label }) => testTypes.includes((label || '').toLowerCase()));
  const hasAssessments = !!(assessmentTypes.length && (hasAssessio || hasRefapp || hasAlvaLabs || hasSri));

  const convertFromIdToName = (value) => {
    const foundTest = selectedTests.find((item) => item.id === value);

    if (foundTest) {
      return foundTest.label;
    }

    return '<Unknown test type>';
  };

  const findTest = (id) => {
    const foundTest = selectedTests.find((item) => item.id === id);

    if (foundTest) {
      return foundTest;
    }

    return null;
  };

  return {
    hasAssessio,
    hasAssessments,
    hasRefapp,
    hasAlvaLabs,
    hasHubert,
    hasSri,
    convertFromIdToName,
    findTest,
    options: selectedTests,
    activeOptions: selectedTests.filter((item) => item.active),
    inactiveOptions: selectedTests.filter((item) => !item.active),
    testIds: selectedTests.map((item) => item.id),
    assessmentsIds: Assessments,
  };
};

export default useAssessment;

import classNames from 'classnames';
import { kebabCase } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import Placeholder from '../../../components/@v2/Placeholder';
import Comments from '../../../components/candidates/Comments';
import Documents from '../../../components/candidates/Documents';
import Email from '../../../components/candidates/Email';
import References from '../../../components/candidates/References';
import Screening from '../../../components/candidates/Screening';
import Tests from '../../../components/candidates/Tests';
import Timeline from '../../../components/candidates/Timeline';
import useAssessment from '../../../hooks/useAssessment';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../store';
import { setCandidatesTab } from '../../../store/slices/settings.slice';
import { getCandidateDetails as getCandidateDetailsThunk } from '../../../store/thunks/candidates.thunks';
import TestWrapper from './CandidateTests';

const CandidateTabs = ({ singleSelected, activeCandidate, getCandidatesLoading }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const loading = getCandidatesLoading;

  const documentsLength = useMemo(() => {
    if (activeCandidate?.documents?.length) {
      return activeCandidate?.documents?.filter((item) => {
        return item.fileExt === 'pdf';
      })?.length;
    }

    return 0;
  }, [activeCandidate.documents]);
  const { hasAssessio, hasRefapp, hasAlvaLabs, hasHubert } = useAssessment();
  const candidatesSettings = useSelectorToolkit(({ settings }) => settings.candidates);
  const filters = useSelectorToolkit(({ settings }) => settings.filters.candidates);

  const tabs = useMemo(() => {
    const defaultTabs = ['Email', 'Comments'];
    if (!singleSelected && !loading) return defaultTabs;
    return [
      'Timeline',
      ...defaultTabs,
      'Documents',
      ...(hasAssessio || hasAlvaLabs || hasHubert ? ['Tests'] : []),
      ...(hasRefapp ? ['References'] : []),
      'Screening questions',
    ];
  }, [hasAssessio, hasAlvaLabs, hasHubert, hasRefapp, loading, singleSelected]);

  const tabActive = candidatesSettings?.tab || tabs[0];

  const setTabActive = (payload) => {
    dispatchToolkit(setCandidatesTab(payload));
  };

  useDidMountEffect(() => {
    setTabActive(singleSelected ? 'Timeline' : 'Email');
  }, [singleSelected]);

  const updateCandidate = () => {
    dispatchToolkit(getCandidateDetailsThunk(id));
  };

  return (
    <div className="mt-4">
      <Nav tabs>
        {tabs.map((item) => (
          <NavItem key={item}>
            {!loading && (
              <NavLink
                className={classNames({ active: tabActive === item }, '!px-4')}
                onClick={() => {
                  setTabActive(item);
                }}
                id={`tab--${kebabCase(item.toLowerCase())}`}
                data-testid={`candidates-${kebabCase(item.toLowerCase())}-tab`}
              >
                {item.includes('questions') ? t('general.screening-questions') : t(`general.${item.toLowerCase()}`)}{' '}
                {item === 'Documents' && `(${documentsLength})`}
              </NavLink>
            )}
            {loading && (
              <NavLink className="mx-2">
                <Placeholder height={24} width={100} unitType="px" />
              </NavLink>
            )}
          </NavItem>
        ))}
      </Nav>
      <div className="whitespace-normal tab-content py-4">
        {tabActive === 'Timeline' && (
          <Timeline
            noData
            activeCandidate={activeCandidate}
            entries={activeCandidate?.timelines || []}
            callback={() => {
              updateCandidate();
            }}
            loading={loading}
          />
        )}
        {tabActive === 'Email' && (
          <Email
            activeCandidate={activeCandidate}
            entries={activeCandidate?.timelines || []}
            singleSelected={singleSelected}
            candidatesSettings={candidatesSettings}
            filters={filters}
            callback={() => {
              updateCandidate();
            }}
            loading={loading}
          />
        )}
        {tabActive === 'Comments' && (
          <Comments
            filters={filters}
            activeCandidate={activeCandidate}
            candidates={candidatesSettings?.selected}
            selectAll={candidatesSettings?.selectAll}
            unchecked={candidatesSettings?.unchecked}
            entries={singleSelected ? activeCandidate?.timelines : []}
            callback={async () => {
              updateCandidate();
            }}
            loading={loading}
          />
        )}
        {tabActive === 'Documents' && singleSelected && (
          <Documents
            activeCandidate={activeCandidate}
            callback={() => {
              updateCandidate();
            }}
          />
        )}
        <TestWrapper>
          {tabActive === 'References' && singleSelected && hasRefapp && <References />}
          {tabActive === 'Tests' && singleSelected && (hasAssessio || hasAlvaLabs || hasHubert) && <Tests />}
        </TestWrapper>
        {tabActive === 'Screening questions' && singleSelected && <Screening activeItem={activeCandidate} />}
      </div>
    </div>
  );
};

export default CandidateTabs;

import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from '..';

export const selectSelf = (state: RootState) => state;

export const selectMenu = createSelector(selectSelf, (state) => {
  return get(state, 'settings.menu');
});

export const selectOptions = createSelector(selectSelf, (state) => {
  return get(state, 'settings.options');
});

export const selectTags = createSelector(selectSelf, (state) => {
  return get(state, 'settings.options.tags');
});

export const selectTablePageSize = createSelector(selectSelf, (state) => {
  return get(state, 'settings.table.pageSize');
});

export const selectPosition = createSelector(selectSelf, (state) => {
  return get(state, 'settings.position');
});

export const selectRecruiters = createSelector(selectSelf, (state) => {
  return get(state, 'settings.options.recruiters');
});

export const selectCompanyIndustries = createSelector(selectSelf, (state) => {
  return get(state, 'settings.options.companyIndustries');
});

export const selectClientTypes = createSelector(selectSelf, (state) => {
  return get(state, 'settings.options.clientTypes');
});

export const selectJobFunctions = createSelector(selectSelf, (state) => {
  return get(state, 'settings.options.jobFunctions');
});

export const selectCandidates = createSelector(selectSelf, (state) => {
  return get(state, 'settings.candidates');
});

export const selectRefappQuestionaires = createSelector(selectSelf, (state) => {
  return get(state, 'settings.options.refappQuestionnaires') || [];
});

export const selectAlvaLabsProfiles = createSelector(selectSelf, (state) => {
  return get(state, 'settings.options.alvaLabsProfiles') || [];
});

export const selectHubertInterviewTemplates = createSelector(selectSelf, (state) => {
  return get(state, 'settings.options.hubertInterviewTemplates') || [];
});

export const selectAmsGroups = createSelector(selectSelf, (state) => {
  return get(state, 'settings.options.amsGroups');
});

export const selectSriRecipients = createSelector(selectSelf, (state) => {
  return get(state, 'settings.options.sriRecipients') || [];
});

export const selectSriOrderers = createSelector(selectSelf, (state) => {
  return get(state, 'settings.options.sriOrderers');
});
